import { Modal, Table, Spin } from 'antd'
import { nflTeamNames as TeamToAbbrev } from '../../../../../utils/team-name-to-abbrev'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  .divide {
    border-right: 2px solid #f0f0f0 !important;
  }
`

const PlayerStatsModal = ({ player, playerStats, visible, setVisible, loading, site='dk' }) => {
    if (!player || !player.TeamAbbrev) {
        return null
    }

    return (
        <Modal
            title={`Player Stats`}
            visible={visible}
            onOk={()=>{
                setVisible(false)
            }}
            onCancel={()=>{
                setVisible(false)
            }}
            style={{ top: '10%'}}
            width={'70%'}
            destroyOnClose={true}
            footer={null}
        >
            <div>
                <div>
                    <h2>{player.Name}</h2>
                    <div style={{fontSize: '16px', display: 'inline-block'}}>
                        {player.Position}
                    </div>
                    <img 
                        src={require(`../../../../../assets/images/nfl/teamlogos/${player.TeamAbbrev}.png`).default} 
                        style={{width: '24px', marginLeft: '10px', display: 'inline-block'}} 
                        alt={TeamToAbbrev[player.TeamAbbrev]}
                    />
                </div>
                <div style={{margin: '15px 0px'}}>
                    {
                        loading ? (
                            <div style={{marginTop: '10px', textAlign: 'center'}}>
                                <Spin />
                            </div>
                        ) : (
                            <PlayerStatsTable playerStats={playerStats} site={site} loading={loading} />
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}

const PlayerStatsTable = ({ playerStats, site, loading }) => {
    const cols = [
    {
        title: 'Week',
        dataIndex: 'Week',
        key: 'Week',
        width: '60px'
    }, 
    {
        title: 'Opp.',
        dataIndex: 'Opponent',
        key: 'Oppoenent',
        width: '60px',
        render: (val) => {
            return (
                <div>
                    {TeamToAbbrev[val]}
                </div>
            )
        }
    }, 
    {
        title: 'Pts',
        key: 'pts',
        className: 'divide',
        width: '65px',
        render: (row) => {
            const siteToKey = {
                'dk': 'DKPts',
                'fd': 'FDPts',
                'ya': 'YAPts',
            }
            return (
                <b>
                    {row[siteToKey[site]]}
                </b>
            )
        }
    },
    {
        title: 'Pass Att.',
        dataIndex: 'PassAtt',
        key: 'PassAtt',
    },
    {
        title: 'Pass Cmp.',
        dataIndex: 'PassCmp',
        key: 'PassComp',
        width: '100px'
    },
    {
        title: 'Pass Yds',
        dataIndex: 'PassYds',
        key: 'PassingYds',
    }, {
        title: 'Pass TDs',
        dataIndex: 'PassTds',
        key: 'PassingTDs',
    }, {
        title: 'Int.',
        dataIndex: 'PassInt',
        key: 'Interceptions',
        className: 'divide',
        width: '50px',
    }, {
        title: 'Rush Yds',
        dataIndex: 'RushYds',
        key: 'RushingYds',
    }, {
        title: 'Rush Att.',
        dataIndex: 'RushAtt',
        key: 'RushAtt',
    }, {
        title: 'Rush TDs',
        dataIndex: 'RushTds',
        className: 'divide',
        key: 'RushingTDs',
    }, {
        title: 'Tgt.',
        dataIndex: 'Tar',
        key: 'Targets',
        width: '55px',
    }, {
        title: 'Rec.',
        dataIndex: 'Rec',
        key: 'Receptions',
        width: '55px',
    }, {
        title: 'Rec Yds',
        dataIndex: 'RecYds',
        key: 'ReceivingYds',
    }, {
        title: 'Rec TDs',
        dataIndex: 'RecTds',
        key: 'ReceivingTDs',
        className: 'divide',
    }, {
        title: 'Fum.',
        dataIndex: 'FmbLss',
        key: 'Fumbles',
        width: '50px',
    }
]

    return (
        <StyledTable 
            columns={cols}
            dataSource={playerStats}
            pagination={false}
            size={'small'}
            rowKey={(row) => row.Week}
            scroll={{ y: '100%', x: '800px' }}
            loading={loading}
        />
    )
}

export default PlayerStatsModal