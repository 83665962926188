export const EXP_SETTINGS_KEY = 'exposure-defaults'

export const localStorageKeyPlayers = (slate, site, sport, counter) => `${sport}-${counter}-${site}-${slate}-players`

export const localStorageKeyStacks = (slate, site, sport, counter) => `${sport}-${counter}-${site}-${slate}-stacks`

export const localStorageKeyGroups = (slate, site, sport, counter) => `${sport}-${counter}-${site}-${slate}-groups-v0.4`

export const TAG_KEY = 'daily-nba-player-tags'

export const GENERAL_SETTINGS_KEY = (sport, site, slate) => `${sport}-${site}-${slate}-general-settings_v0.2`
