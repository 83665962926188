import { Col, Row, Alert, Button, Carousel, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { week, season } from '../../utils/nfl-week'
import * as lineupActions from '../../actions/lineup'

import { MainSlate } from '../../constants/slates'

import MillyWin from '../../assets/images/winningScreenShots/mm-phone.png'
import NBAWin from '../../assets/images/winningScreenShots/nba-double.png'

import Hero from './components/hero'
import MenuSection from './components/menu-section'

import ReactGA from 'react-ga'

import './home.css'

const HomeContainer = styled.div`
  color: white;
  background: #eaecef;
  min-height: 100vh;
  font-size: 16px;
  line-height: 26px;
  overflow-x: hidden;
  width: 100vw;
`
const MainContentCol = styled(Col)`
  overflow-y: scroll;
  height: 100vh;
  position: relative;
`

const SecondaryContainer = styled.div`
  padding: 0px 5%;
`
const SecondaryHeading = styled.div`
  font-size: 30px;
  color: #1d3557;
  margin-bottom: 25px;
  font-weight: 600;
`
const SecondarySubtext = styled.div`
  font-size: 20px;
  color: #59585a;
  margin-bottom: 15px;
`
const ProofImg = styled.img`
  height: 500px;
`
const ProofImgOffset = styled(ProofImg)`
  margin-left: 50px;

  @media (max-width: 767px) {
    margin-left: 0px;
    margin-top: 50px;
  }
`

const Home = ({ allSlates, fetchAllSlates }) => {
  ReactGA.initialize('UA-146608705-1')
  ReactGA.pageview('/')

  return (
    <HomeContainer className="Home">
      <Row>
        <MainContentCol>
          <Hero />
          <MenuSection slates={allSlates} />
          <SecondaryContainer style={{margin: '70px 0px', textAlign: 'center'}}>
          </SecondaryContainer>
          <SecondaryContainer style={{margin: '70px 0px'}}>
            <SecondaryHeading>Proven Success</SecondaryHeading>
            <SecondarySubtext>
            Like most beginners, DFSForecast's co-founder had difficulty winning when he first started playing Daily Fantasy Sports. He kept seeing the same names pop up among the leaderboards and he thought to himself, what are they doing differently?
            </SecondarySubtext>
            <SecondarySubtext>
            What began as an abstract question quickly turned into concrete questions: how often are winners stacking, what sort of stacks are they creating, what flex positions are they using, etc.
            </SecondarySubtext>
            <SecondarySubtext>
            He began his mission to build an optimizer that incorporated the answers to these questions directly into a powerful yet intuitive optimizer. Success soon followed as he won the 2018 Week 4 Milly Maker in the first season the optimizer was operational. Several more GPP wins followed.
            </SecondarySubtext>
            <SecondarySubtext>
            With DFSForecast, you are getting an optimizer that uses data from actual winners and has proven success. Stop guessing how winning lineups are built, and start winning.
            </SecondarySubtext>
            <Row>
              <Col lg={24} md={24} sm={12} xs={12} style={{marginTop: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <ProofImg src={MillyWin} />
                  <ProofImgOffset src={NBAWin} />
                </div>
              </Col>
            </Row>
          </SecondaryContainer>
        </MainContentCol>
      </Row>
    </HomeContainer>
  )
}

export default connect(
  state => ({
    allSlates: state.lineup.allSlates
  }),
  {
    fetchAllSlates: lineupActions.fetchAllSlates,
  }
)(Home)
