export const baseURIui = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:5002' : 'https://ui.dfsforecast.com'
export const partnerURI = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:5005' : 'https://partner.dfsforecast.com'
export const JWT_LOOKUP = 'jwt'

export const playersURI = (site='fd') => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development')
    return 'http://localhost:5005'

  return `https://${site}-players.dfsforecast.com`
  // return `https://ui.dfsforecast.com`
}

export const IMAGE_BASE_URI = "https://ftn-tool-images.s3.amazonaws.com"