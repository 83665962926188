import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import FakurianDesign from '../../../assets/images/backgrounds/fakurian-design.png'
import PathCut from '../../../assets/images/home-images/path-cut-shadow.png'
import PathCutGif from '../../../assets/images/home-images/path-cut-smooth-full.gif'

const HeroContainer = styled.div`
    padding: 120px 5% 50px 5%;
    position: relative;
    text-align: left;
    min-height: 520px;
    background: #1d3557;

    @media (min-width: 1300px) {
        background-size: 60% auto;
        background-position-Y: -40px;
        background-position-X: 90%;
    }
    @media (max-width: 1300px) {
        background-position-Y: 0px;
        background-position-X: 130%;
        background-size: 80% auto;
    }
    @media (max-width: 745px) {
        background-position-Y: 30%;
        background-position-X: 10%;
        background-size: 150% auto;
    }
    background-image: url(${PathCut});
    background-repeat: no-repeat;
`
const Heading = styled.div`
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
`
const SubHeading = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    padding-top: 60px;
    position: relative;
    z-index: 1;
`
const ActionButton = styled.div`
    background: #ffa500;
    border-radius: 10px;
    border: 1px solid #ffa500;
    color: #f1faee;
    padding: 15px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        color: white;
        border: 1px solid #ffd17e;
    }
`
const PathCutImg = styled.img`
    width: 100%;
    z-index: 0;
    position: relative;
    left: 0px;
    opacity: 0.8;
`

const Hero = () => {
    return (
        <HeroContainer>
            <Row>
                <Col lg={14}>
                    <Heading>Innovative, Powerful and Easy To Use DFS Tools.</Heading>
                    <SubHeading>
                        No more guesswork. We build tools to help you win, by studying winners.
                        <br/>
                        <br/>
                        Our unique Lineup Builder Presets, On/Off, Analysis and Projections incorporate data from previous GPP winners and instantly eliminate losing strategies.
                    </SubHeading>
                </Col>
                <Col lg={10}>
                    
                </Col>
            </Row>
            
            <Row style={{marginTop: '60px'}}>
                <Col lg={5} sm={8} xs={12} offset={0} style={{marginBottom: '10px'}}>
                    <Link to="/learn-more">
                        <ActionButton>Learn More</ActionButton>
                    </Link>
                </Col>
            </Row>
            
        </HeroContainer>
    )
}

export default Hero