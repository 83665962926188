import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { overridePrimary } from '../../../../../constants/partner'

import LineupComparisonModal from './lineup-comparison-modal'

const StyledTable = styled(Table)`
  border-radius: 3px;
  margin-top: 38px;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #41464f !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }

  td {
    &.blurry-text {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);

      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;

      /*
       Introduced in IE 10.
       See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;
    }

    &.greyed {
      background-color: ${overridePrimary};
    }
  }
`
const StyledModal = styled.div`
  position: absolute;
  top: 40px;
  z-index: 1;
  right: 15px;
`

const lineupComparisonCols = (permissioned) => {
  const permClassName = permissioned ? '' : 'blurry-text'

  const children = [
    {
      title: 'Metric',
      dataIndex: 'Metric',
      key: 'Metric'
    },
    {
      title: 'My Pct.',
      dataIndex: 'MyPcts',
      key: 'MyPcts',
      className: 'greyed',
      width: '15%',
      render: (text) => (Number(text) * 100).toFixed(2)
    },
    {
      title: 'Top 5 Finish',
      dataIndex: 'Finish1to5',
      className: permClassName,
      key: 'Finish1to5',
      width: '15%',
    },
    {
      title: 'Top 50 Finish',
      dataIndex: 'Finish6to50',
      className: permClassName,
      key: 'Finish6to50',
      width: '15%',
    },
    {
      title: 'Cashed',
      dataIndex: 'Cashed',
      className: permClassName,
      key: 'Cashed',
      width: '15%',
    },
    {
      title: 'Lost',
      dataIndex: 'Lost',
      className: permClassName,
      key: 'Lost',
      width: '15%',
    }
  ]

  return children
}

const LineupComparisonChart = ({ lineupComparisonData, loading, permissioned }) => {

  return (
    <div>
      <StyledTable
        columns={lineupComparisonCols(permissioned)}
        dataSource={lineupComparisonData}
        loading={loading}
        scroll={{ x: '90%', y: '300px' }}
        bordered
        size='small'
        pagination={false}
        rowKey={(_row, i) => {
          return `lineup-comparison-${String(i)}`
        }}
      />
    </div>
  )
}

export default LineupComparisonChart
