export const START_CHANNEL = 'START_CHANNEL'
export const STOP_CHANNEL = 'STOP_CHANNEL'
export const CHANNEL_ON = 'CHANNEL_ON'
export const CHANNEL_OFF = 'CHANNEL_OFF'
export const SERVER_ON = 'SERVER_ON'
export const SERVER_OFF = 'SERVER_OFF'

// action creators for Stop and Start buttons. You can also put them into componentDidMount
export const startChannel = () => ({type: START_CHANNEL})
export const stopChannel = () => ({type: STOP_CHANNEL})
