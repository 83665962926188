import React, { useState, Component } from 'react'
import { Row, Col, Card, Input, Button } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as authActions from '../../actions/auth'
import * as accountActions from '../../actions/account'
import Logo from '../../assets/images/logo-filled.png'

const ForgotPasswordContainer = styled.div`
  min-height: 94vh;
  padding: 70px;
  background: #eaecef;
`
const ForgotPasswordCard = styled(Card)`
  border: 2px solid #cccccc !important;
  border-radius: 12px !important;
  margin: 100px 25% 0px 25% !important;
  box-shadow: 2px 2px 40px #e6e6e6;
`
const HeadingText = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
`
const StyledInput = styled(Input)`
  width: 70% !important;
  border-radius: 0px !important;
  font-size: 20px !important;
  line-height: 44px;

  .ant-input {
    border-radius: 0px !important;
    font-size: 20px !important;
    line-height: 44px;
  }

  .ant-input-prefix {
    margin-right: 7px;
  }

  .error {
    .ant-input {
      border: 1px solid red !important;
    }
  }
`
const LinkText = styled.div`
  font-size: 12px;
  color: blue;
`
const LogoImg = styled.img`
  height: 70px;
`
const Underline = styled.div`
  width: 65%;
  margin-left: 20px;
  border-top: 1px solid #bfbfbf;

  &.error {
    border-top: 1px solid #ff6666;
  }
`
const ClickableText = styled.div`
  cursor: pointer;
  margin: 0px 2px;
`
const ErrorText = styled.div`
  text-align: center;
  color: #ff4d4d;
  font-size: 12px;
  margin: 10px 0px 10px 0px;
`

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

class ForgotPasswordBox extends Component {

  componentWillUpdate(nextProps, nextState) {
    // Refresh if change in account
    if (
      this.props.account.forgotPassword.updating && !nextProps.account.forgotPassword.updating
    ) {
      const redirectTo = getQueryVariable('redirectTo')
      const plan = getQueryVariable('plan')
      window.location = window.location.protocol + "//" + window.location.host + "/" + (redirectTo || '') + (plan ? `?plan=${plan}` : '')
    }
  }

  render() {
    return (
      <ForgotPassword
        initiateForgotPassword={this.props.fetchForgotPassword}
      />
    )
  }
}

const ForgotPassword = ( { initiateForgotPassword, error } ) => {
  const [ errors, setErrors ] = useState([])
  const [ email, setEmail ] = useState('')

  const setInputValue = (index, value) => {
    switch (index) {
      case 0:
        setEmail(value)
        if (!validateEmail(value)) {
          const _errors = [...errors]
          _errors[index] = "Invalid email address"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index]) {
            const _errors = [...errors]
            _errors[index] = undefined
            setErrors(_errors)
          }
        }
        break
      default:
        break
    }
  }

  const validateForm = () => {
    const allFilled = (email)
    let noErrors = true
    errors.forEach(e => {
      if (e) {
        noErrors = false
      }
    })

    return (allFilled && noErrors)
  }

  const sendForgotPassword = () => {
    // initate login
    initiateForgotPassword({
      email
    })
  }

  const redirectTo = getQueryVariable('redirectTo')
  const plan = getQueryVariable('plan')

  return (
    <ForgotPasswordContainer>
      <ForgotPasswordCard>
        <HeadingText>
          <LogoImg src={Logo} />
        </HeadingText>
        <HeadingText>
          Forgot Password
          <div style={{textAlign: 'center', marginTop: '40px', color: '#999999', fontSize: '16px'}}>
            An email will be sent with instructions for resetting your password.
          </div>
        </HeadingText>
        <ErrorText>{error}</ErrorText>
        <Row style={{marginTop: '50px'}}>
          <Col lg={14} offset={6}>
            <StyledInput
              bordered={false}
              onChange={(e) => {
                setInputValue(0, e.target.value)
              }}
              size="large"
              className={
                errors[0] ? 'error' : ''
              }
              prefix={(
                <sup style={{color: 'red'}}>*</sup>
              )}
              placeholder={'Email'}
              type="text"
            />
            <Underline
              className={
                errors[0] ? 'error' : ''
              }
            />
            {
              errors[0] ? (
                <div style={{color: '#ff6666', marginLeft: '20px'}}>{errors[2]}</div>
              ) : ''
            }
          </Col>
        </Row>
        <div style={{marginTop: '40px', marginLeft: '40%'}}>
          <Button
            disabled={!validateForm()}
            size={'large'}
            shape='round'
            type='primary'
            style={{width: '160px'}}
            onClick={() => {
              sendForgotPassword()
            }}
          >
            Submit
          </Button>
        </div>
      </ForgotPasswordCard>
    </ForgotPasswordContainer>
  )
}

export default connect(
  state => ({
    auth: state.auth,
    account: state.account
  }),
  {
    logout: authActions.logout,
    fetchForgotPassword: accountActions.fetchForgotPassword
  }
)(ForgotPasswordBox)
