import React, { useState, Component } from 'react'
import { Row, Col, Card, Input, Button } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as authActions from '../../actions/auth'
import * as accountActions from '../../actions/account'
import Logo from '../../assets/images/logo-filled.png'
import TOS from '../../assets/agreements/tos.pdf'
import Privacy from '../../assets/agreements/privacy.pdf'

const SignUpContainer = styled.div`
  min-height: 94vh;
  padding: 70px;
  background: #eaecef;

  @media (max-width: 1280px) {
    padding: 30px;
  }
`
const SignUpCard = styled(Card)`
  border: 2px solid #cccccc !important;
  border-radius: 12px !important;
  margin: 100px 15% 0px 15% !important;
  box-shadow: 2px 2px 40px #e6e6e6;

  @media (max-width: 1280px) {
    margin: 100px 0px 0px 0px !important;
  }
`
const HeadingText = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
`
const StyledInput = styled(Input)`
  width: 70% !important;
  border-radius: 0px !important;
  font-size: 20px !important;
  line-height: 44px;

  .ant-input {
    border-radius: 0px !important;
    font-size: 20px !important;
    line-height: 44px;
  }

  .ant-input-prefix {
    margin-right: 7px;
  }

  .error {
    .ant-input {
      border: 1px solid red !important;
    }
  }
`
const LinkText = styled.div`
  font-size: 12px;
  color: blue;
`
const LogoImg = styled.img`
  height: 70px;
`
const Underline = styled.div`
  width: 65%;
  margin-left: 20px;
  border-top: 1px solid #bfbfbf;

  &.error {
    border-top: 1px solid #ff6666;
  }
`
const Terms = styled.a`
  text-decoration: none;
  margin: 0px 2px;
`
const ErrorText = styled.div`
  text-align: center;
  color: #ff4d4d;
  font-size: 12px;
  margin: 10px 0px 10px 0px;
`

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

class SignUpBox extends Component {

  componentWillUpdate(nextProps, nextState) {
    // Refresh if change in account
    if (
      this.props.auth.loggedin !== nextProps.auth.loggedin
    ) {
      const redirectTo = getQueryVariable('redirectTo')
      const plan = getQueryVariable('plan')
      const sport = getQueryVariable('sport')
      if (plan == 0) {
        window.location = window.location.protocol + "//" + window.location.host + "/" + (redirectTo || '')
      } else {
        window.location = window.location.protocol + "//" + window.location.host + "/" + (redirectTo || '') + (plan ? `?plan=${plan}` : '') + (sport ? `&sport=${sport}` : '')
      }
    }
  }

  render() {
    return (
      <SignUp
        initiateSignup={this.props.initiateSignup}
        error={this.props.auth.failedSignup ? (this.props.auth.errorMessage) : ''}
     />
    )
  }
}

const SignUp = ( { initiateSignup, error } ) => {
  const [ errors, setErrors ] = useState([])
  const [ firstName, setFirstName ] = useState('')
  const [ lastName, setLastName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ dupPassword, setDupPassword ] = useState('')

  const setInputValue = (index, value) => {
    switch (index) {
      case 0:
        setFirstName(value)
        if (!value) {
          const _errors = [...errors]
          _errors[index] = "Field cannot be empty"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index]) {
            const _errors = [...errors]
            _errors[index] = undefined
            setErrors(_errors)
          }
        }
        break
      case 1:
        setLastName(value)
        if (!value) {
          const _errors = [...errors]
          _errors[index] = "Field cannot be empty"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index]) {
            const _errors = [...errors]
            _errors[index] = undefined
            setErrors(_errors)
          }
        }
        break
      case 2:
        setEmail(value)
        if (!validateEmail(value)) {
          const _errors = [...errors]
          _errors[index] = "Invalid email address"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index]) {
            const _errors = [...errors]
            _errors[index] = undefined
            setErrors(_errors)
          }
        }
        break
      case 3:
        setPassword(value)
        if (!value) {
          const _errors = [...errors]
          _errors[index] = "Field cannot be empty"
          setErrors(_errors)
        } else if (value !== dupPassword) {
          const _errors = [...errors]
          _errors[index] = "Passwords do not match"
          _errors[index+1] = "Passwords do not match"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index] || errors[index + 1]) {
            const _errors = [...errors]
            _errors[index] = undefined
            _errors[index+1] = undefined
            setErrors(_errors)
          }
        }
        break
      case 4:
        setDupPassword(value)
        if (!value) {
          const _errors = [...errors]
          _errors[index] = "Field cannot be empty"
          setErrors(_errors)
        } else if (value !== password) {
          const _errors = [...errors]
          _errors[index] = "Passwords do not match"
          _errors[index-1] = "Passwords do not match"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index] || errors[index - 1]) {
            const _errors = [...errors]
            _errors[index] = undefined
            _errors[index-1] = undefined
            setErrors(_errors)
          }
        }
        break
      default:
        break
    }
  }

  const validateForm = () => {
    const allFilled = (firstName && lastName && email && password && dupPassword)
    let noErrors = true
    errors.forEach(e => {
      if (e) {
        noErrors = false
      }
    })

    return (allFilled && noErrors)
  }

  const createAccount = () => {
    initiateSignup({
      email,
      password,
      first_name: firstName,
      last_name: lastName
    })
  }

  const redirectTo = getQueryVariable('redirectTo')
  const plan = getQueryVariable('plan')
  const sport = getQueryVariable('sport')

  return (
    <SignUpContainer>
      <SignUpCard>
        <HeadingText>
          <LogoImg src={Logo} />
        </HeadingText>
        <HeadingText>
          Create Account
          <LinkText>
            <Link to={'/login' + (redirectTo ? `?redirectTo=${redirectTo}` : '') + (plan ? `&plan=${plan}` : '') + (sport ? `&sport=${sport}` : '')}>Already have an account? Login</Link>
          </LinkText>
        </HeadingText>
        <ErrorText>{error}</ErrorText>
        <Row style={{marginTop: '50px'}}>
          <Col lg={11} offset={1}>
            <StyledInput
              bordered={false}
              size="large"
              onChange={(e) => {
                setInputValue(0, e.target.value)
              }}
              prefix={(
                <sup style={{color: 'red'}}>*</sup>
              )}
              placeholder={'First Name'}
              type="text"
            />
            <Underline className={
              errors[0] ? 'error' : ''
            } />
            {
              errors[0] ? (
                <div style={{color: '#ff6666', marginLeft: '20px'}}>{errors[0]}</div>
              ) : ''
            }
          </Col>
          <Col lg={12}>
            <StyledInput
              bordered={false}
              size="large"
              onChange={(e) => {
                setInputValue(1, e.target.value)
              }}
              className={
                errors[1] ? 'error' : ''
              }
              prefix={(
                <sup style={{color: 'red'}}>*</sup>
              )}
              placeholder={'Last Name'}
              type="text"
            />
            <Underline
              className={
                errors[1] ? 'error' : ''
              }
            />
            {
              errors[1] ? (
                <div style={{color: '#ff6666', marginLeft: '20px'}}>{errors[1]}</div>
              ) : ''
            }
          </Col>
        </Row>
        <Row style={{marginTop: '50px'}}>
          <Col lg={11} offset={1}>
            <StyledInput
              bordered={false}
              onChange={(e) => {
                setInputValue(2, e.target.value)
              }}
              size="large"
              className={
                errors[2] ? 'error' : ''
              }
              prefix={(
                <sup style={{color: 'red'}}>*</sup>
              )}
              placeholder={'Email'}
              type="text"
            />
            <Underline
              className={
                errors[2] ? 'error' : ''
              }
            />
            {
              errors[2] ? (
                <div style={{color: '#ff6666', marginLeft: '20px'}}>{errors[2]}</div>
              ) : ''
            }
          </Col>
        </Row>
        <Row style={{marginTop: '50px'}}>
          <Col lg={11} offset={1}>
            <StyledInput
              bordered={false}
              size="large"
              onChange={(e) => {
                setInputValue(3, e.target.value)
              }}
              className={
                errors[3] ? 'error' : ''
              }
              prefix={(
                <sup style={{color: 'red'}}>*</sup>
              )}
              type="password"
              placeholder="Password"
            />
            <Underline
              className={
                errors[3] ? 'error' : ''
              }
            />
            {
              errors[3] ? (
                <div style={{color: '#ff6666', marginLeft: '20px'}}>{errors[3]}</div>
              ) : ''
            }
          </Col>
          <Col lg={12}>
            <StyledInput
              bordered={false}
              size="large"
              onChange={(e) => {
                setInputValue(4, e.target.value)
              }}
              className={
                errors[4] ? 'error' : ''
              }
              prefix={(
                <sup style={{color: 'red'}}>*</sup>
              )}
              type="password"
              placeholder="Re-Enter Password"
            />
            <Underline
              className={
                errors[4] ? 'error' : ''
              }
            />
            {
              errors[4] ? (
                <div style={{color: '#ff6666', marginLeft: '20px'}}>{errors[4]}</div>
              ) : ''
            }
          </Col>
        </Row>
        <div style={{textAlign: 'center', marginTop: '40px', color: '#999999'}}>By creating an account you acknowledge that you have read and understand our <Terms href={Privacy}>Privacy Policy</Terms> and <Terms href={TOS}>Terms of Service</Terms></div>
        <div style={{marginTop: '40px', textAlign: 'center'}}>
          <Button
            disabled={!validateForm()}
            size={'large'}
            shape='round'
            type='primary'
            style={{width: '160px'}}
            onClick={() => {
              createAccount()
            }}
          >
            Submit
          </Button>
        </div>
      </SignUpCard>
    </SignUpContainer>
  )
}

export default connect(
  state => ({
    auth: state.auth,
    account: state.account
  }),
  {
    initiateLogin: authActions.initiateLogin,
    initiateSignup: authActions.initiateSignup,
    logout: authActions.logout,
    fetchForgotPassword: accountActions.fetchForgotPassword
  }
)(SignUpBox)
