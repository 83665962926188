import { createActions } from 'lessdux'

export const login = {
  ...createActions('LOGIN'),
  LOGOUT: 'LOGIN_LOGOUT',
  LOGOUT_COMPLETE: 'LOGOUT_COMPLETE',
  SIGNUP: 'LOGIN_SIGNUP',
  CHECK: 'LOGIN_CHECK'
}

export const partnerLogin = {
  ...createActions('PARTNER_LOGIN'),
  CHECK: 'PARTNER_LOGIN_CHECK'
}

export const signup = createActions('SIGNUP')

export const initiateLogin = payload => ({
  type: login.FETCH,
  payload
})

export const logout = () => ({
  type: login.LOGOUT
})

export const initiateSignup = payload => ({
  type: signup.FETCH,
  payload
})

export const checkToken = () => ({
  type: login.CHECK
})

export const checkPartnerToken = (token, sport) => {
  return {
    type: partnerLogin.CHECK,
    token,
    sport
  }
}

