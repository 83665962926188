export const POSITIONLESS_SPORTS = ['pga']

export const POSITIONLESS_LU_HEADING = {
  'pga': {
    'dk': 'G',
    'fd': 'G'
  },
  'mma': {
    'dk': 'F'
  }
}

export const GET_POSITIONLESS_ROSTER_POSITIONS = (luSize, sport, site) => {
  const headingPrefix = POSITIONLESS_LU_HEADING[sport][site]

  let rosterPos = []
  for (let i=0; i<luSize; i++) {
    rosterPos.push(`${headingPrefix}${i + 1}`)
  }

  return rosterPos
}

export const GET_POSITIONLESS_COLS = (luSize, sport, site) => {
  const rosterPositions = GET_POSITIONLESS_ROSTER_POSITIONS(luSize, sport, site)
  let cols = []
  
  for (let i=0; i<luSize; i++) {
    const pos = rosterPositions[i]
    cols.push(
      {
        title: pos,
        dataIndex: pos,
        key: pos,
        render: (player) => {return player.Name}
      },
    )
  }

  return cols
}