import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import PresetsGif from '../../assets/images/screencaps/presets.gif'
import OnOffImg from '../../assets/images/screencaps/on-off.png'
import LineupComparison from '../../assets/images/screencaps/lineupcomparison.png'
import Stacks from '../../assets/images/screencaps/stacks.png'
import Groups from '../../assets/images/screencaps/groups.png'
import NFLLogo from '../../assets/images/nfllogo.png'
import NBALogo from '../../assets/images/nba-logo.png'

import PathCutGif from '../../assets/images/home-images/path-cut-smooth-full.gif'

const HeroContainer = styled.div`
    padding: 160px 5% 50px 5%;
    position: relative;
    text-align: left;
    min-height: 520px;
    background: #1d3557;
    color: #fff;
`
const LogoImg = styled.img`
    height: 20px;
    margin: 0px 5px 5px 5px;
`
const Heading = styled.div`
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
`
const SubHeading = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    padding-top: 60px;
    position: relative;
    z-index: 1;
`
const Subsection = styled.div`
    padding: 160px 5% 80px 5%;
    position: relative;
    text-align: left;
    min-height: 520px;
    background: #fff;
    color: #1d3557;
`
const SubsectionOffset = styled.div`
    padding: 160px 5% 80px 5%;
    position: relative;
    text-align: left;
    min-height: 520px;
    background: #efefef;
    color: #1d3557;
`
const SectionImg = styled.img`
    width: 100%;
    box-shadow: 2px 2px 10px #9e9e9e;
`
const OffsetSectionImg1 = styled(SectionImg)`
    position: absolute;
    top: -135px;
    left: -40px;
    width: 80%;
`
const OffsetSectionImg2 = styled(SectionImg)`
    position: absolute;
    top: -55px;
    left: 20px;
    width: 80%;
`
const OffsetSectionImg3 = styled(SectionImg)`
    position: absolute;
    top: 25px;
    left: 80px;
    width: 80%;
`
const ActionButton = styled.div`
    background: transparent;
    border-radius: 10px;
    color: #4ab2ed;
    border: 1px solid #4ab2ed;
    padding: 15px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    width: 180px;

    &:hover {
        border: 1px solid #4ab2ed;
    }

    @media (max-width: 991px) {
        margin-bottom: 50px;
    }
`

export default () => {
    return (
        <div>
            <HeroContainer style={{backgroundImage: `url(${PathCutGif})`, backgroundRepeat: 'no-repeat', backgroundSize: '80% auto', backgroundPositionY: '-90px', backgroundPositionX: '80%',}}>
                <Heading>The DFSForecast Advantage</Heading>
                <SubHeading>
                    DFSForecast tools are designed and built for everyone: beginners, seasoned pros and everyone in between.
                    <br/>
                    <br/>
                    Whether you’re entering winning lineups 5-minutes before a contest using our Lineup Builder Presets or finding your edge with our innovative On/Off and analytics tools, everything we make is easy to use, highly customizable and based on data from previous winners.
                 </SubHeading>
            </HeroContainer>
            <Subsection>
                <Row>
                    <Col lg={12}>
                        <Heading>DFSForecast Lineup Builders</Heading>
                        <SubHeading style={{paddingTop: '10px'}}>Winning Lineups from Previous Winners.</SubHeading>
                        <SubHeading>Our unique Lineup Builder includes Presets that instantly tailor your settings based on the characteristics and strategies of previous Milly Maker winners. You can also customize your build with tons of settings and instantly check your potential to win using our post-build analysis.</SubHeading>
                        <Row style={{marginTop: '100px'}}>
                            <Col lg={10} md={12} style={{marginRight: '5px'}}>
                                <Link to="/optimizer/classic/nfl/dk/Main">
                                    <ActionButton><LogoImg src={NFLLogo} /> NFL</ActionButton>
                                </Link>
                            </Col>
                            <Col lg={10} md={12}>
                                <Link to="/optimizer/classic/nba/dk/Main">
                                    <ActionButton><LogoImg src={NBALogo} />  NBA</ActionButton>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10} offset={2}>
                        <SectionImg src={PresetsGif} />
                    </Col>
                </Row>
            </Subsection>
            <SubsectionOffset>
                <Row>
                    <Col lg={11}>
                        <SectionImg src={OnOffImg} />
                        <Row style={{marginTop: '60px'}}>
                            <Col lg={10} md={12} offset={8} style={{marginRight: '5px'}}>
                                <Link to="/analysis/nba/dk/Main/on-off">
                                    <ActionButton>Try On/Off</ActionButton>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={11} offset={1}>
                        <Heading>DFSForecast On/Off Tool</Heading>
                        <SubHeading style={{paddingTop: '10px'}}>Slate-specific Value Metrics</SubHeading>
                        <SubHeading>
                            Our unique NBA On/Off tool not only predicts players’ fantasy performance without their teammate(s), but also provides a clear, innovative metric of each player’s value and how they compare to the current slate.
                            <br/><br/>
                            This tool answers a vital question for GPP contests: when a player is ruled out, which of their teammates are the most valuable in the current slate given their salary and projected minutes?
                        </SubHeading>
                    </Col>
                </Row>
            </SubsectionOffset>
            <Subsection>
                <Row>
                    <Col lg={12}>
                        <Heading>DFSForecast Analysis</Heading>
                        <SubHeading style={{paddingTop: '10px'}}>Tons of Data, Easy to Use Tools.</SubHeading>
                        <SubHeading>We analyzed the data for you. From our Stack Analysis, Stack Simulator Tools to Milly Maker Leverage scores and Player Props, we built every tool to provide your edge over the rest of the field.</SubHeading>
                        <Row style={{marginTop: '100px'}}>
                            <Col lg={10} md={12} style={{marginRight: '5px'}}>
                                <Link to="/analysis/nfl/dk/Main/stacks">
                                    <ActionButton>NFL Stacks</ActionButton>
                                </Link>
                            </Col>
                            <Col lg={10} md={12}>
                                <Link to="/props">
                                    <ActionButton>Props</ActionButton>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10} offset={2}>
                        <SectionImg src={LineupComparison} />
                    </Col>
                </Row>
            </Subsection>
        </div>
    )
}