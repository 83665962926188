import { Form, Card, Input, Button, Row, Col } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import styled from 'styled-components'
import Logo from '../../assets/images/logo-filled.png'

import * as accountActions from '../../actions/account'

const ResetPasswordContainer = styled.div`
  min-height: 94vh;
  padding: 70px;
  background: #eaecef;
`
const ResetPasswordCard = styled(Card)`
  border: 2px solid #cccccc !important;
  border-radius: 12px !important;
  margin: 100px 25% 0px 25% !important;
  box-shadow: 2px 2px 40px #e6e6e6;
`
const HeadingText = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
`
const StyledInput = styled(Input)`
  width: 70% !important;
  border-radius: 0px !important;
  font-size: 20px !important;
  line-height: 44px;

  .ant-input {
    border-radius: 0px !important;
    font-size: 20px !important;
    line-height: 44px;
  }

  .error {
    .ant-input {
      border: 1px solid red !important;
    }
  }
`
const LinkText = styled.div`
  font-size: 12px;
  color: blue;
`
const LogoImg = styled.img`
  height: 70px;
`
const Underline = styled.div`
  border-top: 1px solid #bfbfbf;

  &.error {
    border-top: 1px solid #ff6666;
  }
`
const ClickableText = styled.div`
  cursor: pointer;
  margin: 0px 2px;
`
const ErrorText = styled.div`
  text-align: center;
  color: #ff4d4d;
  font-size: 12px;
  margin: 10px 0px 10px 0px;
`
const StyledForm = styled(Form)`
  .ant-form-item-explain-error {
    text-align: center;
  }
`

class PasswordForm extends Component {
  handleSubmit = (values) => {
    this.props.onUpdate({ ...values })
  }

  render() {
    return (
      <StyledForm onFinish={this.handleSubmit} className="new-password-form">
        <Form.Item
          name="new_password"
          rules={[
            {
              required: true,
              message: 'Please enter a new password'
            }
          ]}
        >
          <Row style={{marginTop: '50px'}}>
            <Col lg={12} offset={6}>
              <StyledInput.Password
                bordered={false}
                size="large"
                placeholder={'Password'}
                />
              <Underline />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="dup_password"
          rules={[
            {
              required: true,
              message: 'Please enter a new password'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Row style={{marginTop: '50px'}}>
            <Col lg={12} offset={6}>
              <StyledInput.Password
                bordered={false}
                size="large"
                placeholder={'Re-Type Password'}
              />
              <Underline />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <div style={{marginTop: '40px', marginLeft: '40%'}}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size={'large'}
              shape='round'
              style={{width: '160px'}}
            >
              Reset Password
            </Button>
          </div>
        </Form.Item>
      </StyledForm>
    )
  }
}

class ResetPassword extends Component {
  state = {
    successMessage: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.resetPassword.updating && !this.props.account.resetPassword.updating && !this.props.account.resetPassword.failedUpdating)
      this.setState({
        successMessage: true
      })
  }

  handleSubmit({ new_password }) {
    const values = queryString.parse(this.props.location.search)
    this.props.fetchResetPassword({
      token: values.token,
      password: new_password
    })
  }

  render() {
    return (
      <ResetPasswordContainer>
        <ResetPasswordCard>
          <HeadingText>
            <LogoImg src={Logo} />
          </HeadingText>
          <HeadingText>
            Reset Password
          </HeadingText>
          <PasswordForm onUpdate={this.handleSubmit.bind(this)} />
          <div style={{textAlign: 'center'}}>
            {
              this.props.account.resetPassword.failedUpdating ? (
                <ErrorMessage>The link has expired. Please send a new reset password link.</ErrorMessage>
              ) : ''
            }
            {
              this.state.successMessage ? (
                <div>Password changed! Please login with your new password</div>
              ) : ''
            }
          </div>
        </ResetPasswordCard>
      </ResetPasswordContainer>
    )
  }
}

export default connect(
  state => ({
    account: state.account,
  }),
  {
    fetchResetPassword: accountActions.fetchResetPassword
  }
)(ResetPassword)
