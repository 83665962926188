import { Button, Dropdown, Menu } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as authActions from '../../actions/auth'

const StyledButton = styled(Link)`
  background-color: transparent !important;
  color: white !important;
  margin-right: 30px;

  @media (max-width: 500px) {
    margin-right: 10px;
  }
`
const StyledSignup = styled(Link)`
  display: inline-block;
  margin-left: 15px;
  color: white;
  cursor: pointer;
`
const Terms = styled.a`
  text-decoration: none;
  margin: 0px 2px;
`
const dropdownMenu = (logoutFn) => (
  <Menu>
    <Menu.Item>
      <a href={`/account`}>My Account</a>
    </Menu.Item>
    <Menu.Item>
      <div onClick={logoutFn}>Logout</div>
    </Menu.Item>
  </Menu>
)

class LoginMenu extends Component {

  render() {
    const {
      auth
    } = this.props

    return (
      <div>
        {
          auth.loggedin ? (
            <div>
              <Dropdown overlay={dropdownMenu(this.props.logout)} trigger={['click']}>
                <a className="ant-dropdown-link" >
                  Account
                </a>
              </Dropdown>
              <StyledSignup to="/plans" style={{marginLeft: '50px'}}>
                  Plans
              </StyledSignup>
            </div>
            
          ) : (
            <div>
              <StyledButton to="/login">
                  Login
                </StyledButton>
                <StyledSignup to="/plans">
                  Plans
                </StyledSignup>
            </div>
          )
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth,
    account: state.account
  }),
  {
    logout: authActions.logout,
  }
)(LoginMenu)
