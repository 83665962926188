export const HEAT_MAP_POSITIVE_COLOR_SCALE = [
  'rgba(136, 182, 247, 0.1)',
  'rgba(136, 182, 247, 0.3)',
  'rgba(136, 182, 247, 0.5)',
  'rgba(136, 182, 247, 0.6)',
  'rgba(136, 182, 247, 0.7)',
]
export const HEAT_MAP_NEGATIVE_COLOR_SCALE = [
  'rgba(254, 159, 159, 0.1)',
  'rgba(254, 159, 159, 0.3)',
  'rgba(254, 159, 159, 0.5)',
  'rgba(254, 159, 159, 0.6)',
  'rgba(254, 159, 159, 0.7)',
]

export const NEUTRAL = 'rgba(254, 159, 159, 0)'

export const getColorForValueForOU = (v) => {
  let _value = Number(v)
  if (_value >= 0) {
    if (_value <= 36) return 'rgba(254, 159, 159, 1)'
    else if (_value <= 38) return 'rgba(254, 159, 159, 0.6)'
    else if (_value <= 42) return 'rgba(254, 159, 159, 0.3)'
    else if (_value <= 46.5) return NEUTRAL
    else if (_value <= 50) return 'rgba(136, 182, 247, 0.3)'
    else if (_value <= 53) return 'rgba(136, 182, 247, 0.6)'
    else return 'rgba(136, 182, 247, 1)'
  }
}

export const getColorForValueForImpPts = (v) => {
  let _value = Number(v)
  if (_value >= 0) {
    if (_value <= 25 &&  _value >= 22) return NEUTRAL
    else if (_value <= 18) return HEAT_MAP_NEGATIVE_COLOR_SCALE[4]
    else if (_value <= 20) return HEAT_MAP_NEGATIVE_COLOR_SCALE[2]
    else if (_value <= 22) return HEAT_MAP_NEGATIVE_COLOR_SCALE[0]
    else if (_value <= 27) return HEAT_MAP_POSITIVE_COLOR_SCALE[1]
    else if (_value <= 30) return HEAT_MAP_POSITIVE_COLOR_SCALE[2]
    else return HEAT_MAP_POSITIVE_COLOR_SCALE[4]
  }
}

export const getColorForValueForGPPValue = (v) => {
  let _value = Number(v)
  const MAP = _value >= 0 ? HEAT_MAP_POSITIVE_COLOR_SCALE : HEAT_MAP_NEGATIVE_COLOR_SCALE

  if (_value < 0) _value = _value * -1
  if (_value <= 1.5) return NEUTRAL
  else if (_value <= 2.5) return MAP[0]
  else if (_value <= 4) return MAP[2]
  else return MAP[4]
}