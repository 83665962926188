import { createActions } from 'lessdux'

export const afterStartLineups = {
  ...createActions('afterStartLineups'),
  ACTIVATE: 'AFTER_START_ACTIVATE',
  UPDATE: 'AFTER_START_UPDATE',
  TOGGLE: 'AFTER_START_TOGGLE'
}

export const slatesActive = {
  ...createActions('SLATES_ACTIVE')
}

// Go directly to reducer
export const receiveCSVUpload = ({ lineups }) => {  
  return {
    type: afterStartLineups.RECEIVE,
    payload: lineups
  }
}

export const activateAfterStart = ({ activated }) => {
  return {
    type: afterStartLineups.ACTIVATE,
    payload: activated
  }
}

export const activateSlate = ({ slateKey, active }) => {
  return {
    type: afterStartLineups.TOGGLE,
    slateKey,
    active
  }
}