import React, { Component } from 'react'
import { Table } from 'antd'
import styled from 'styled-components'
import { connect } from 'react-redux'

const HoverableDiv = styled.div`
  text-align: center;

  #default {
    display: inline;
  }
  #onHover {
    display: none;
  }

  &:hover {
    #default {
      display: none;
    }
    #onHover {
      display: inline;
    }
  }
`
const StyledTable = styled(Table)`
  &.blurry-text {
    tr:not(:first-child) {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);

      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;

      /*
       Introduced in IE 10.
       See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;
    }
  }
`

const OWNERSHIP_RANGES = [
  2.5,
  5,
  10,
  18,
  25
]

class OwnershipBreakdown extends Component {
  render() {
    const rangeInformation = getRangeInformation(this.props.exposureStats || [])

    return (
      <div>
        <StyledTable
          bordered
          columns={cols()}
          dataSource={rangeInformation}
          size={'small'}
          className={ (!this.props.permissioned) ? 'blurry-text' : '' }
          pagination={false}
        />
      </div>
    )
  }
}

const getRangeInformation = (players) => {
  if (!players) return []

  const rangeInformation = {
    [2.5]: {},
    [5]: {},
    [10]: {},
    [18]: {},
    [25]: {},
  }

  players.forEach((p, i) => {
    // Should have exposure and be a non defense position
    if (p.MaxExp > 0) {
      OWNERSHIP_RANGES.every(r => {
        if (Number(p.ProjOwn) < r) {
          rangeInformation[r]['MaxExp'] = (rangeInformation[r]['MaxExp'] || 0) + p.MaxExp
          rangeInformation[r]['Exp'] = (rangeInformation[r]['Exp'] || 0) + (p.Exp)
          rangeInformation[r]['Count'] = (rangeInformation[r]['Count'] || 0) + 1
        }

        // continue iteration
        return true
      })
    }
  })

  return flattenRangeObject(rangeInformation)
}

const flattenRangeObject = (ranges) => {
  const flattenedRanges = []
  Object.keys(ranges).forEach(k => {
    flattenedRanges.push({
      Range: k,
      ...ranges[k]
    })
  })

  return flattenedRanges
}

const cols = () => ([
  {
    'title': 'Ownership Range',
    'dataIndex': 'Range',
    sorter: (a, b) => Number(b.Range - a.Range),
    defaultSortOrder: 'decend',
    'key': 'range',
    render: (v) => (
      <b>{`<${v}%`}</b>
    )
  },
  {
    'title': 'Player Count',
    'dataIndex': 'Count',
    'key': 'count',
    render: (v) => (
      v || 0
    )
  },
  {
    'title': 'Players Per Lineup',
    'dataIndex': 'Exp',
    'key': 'exposure',
    render: (v) => (
      `${((v || 0)/100).toFixed(2)}`
    )
  },
])

export default connect(
  state => ({})
)(OwnershipBreakdown)
