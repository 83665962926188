import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as afterStartActions from '../actions/after-start'
import { getAfterStartLineups } from '../reducers/after-start'

// Add toggles to each slate
function* recieveUpload({ payload }) {
  const _lineups = {}

  payload.forEach(lu => {
      // get contestID
      const _contestID = lu[2]
      // Haven't seen this contest before
      if (!_lineups[_contestID]) {
          _lineups[_contestID] = {
            lineups: [],
            active: true
          }
      }
      _lineups[_contestID].lineups.push(lu)
  })

  yield put({type: afterStartActions.afterStartLineups.UPDATE, payload: _lineups})
}

function* toggleSlate({ slateKey, active }) {
  let lus = yield select(getAfterStartLineups)
  // Copy lus
  lus = {...lus}

  const slate = {...lus[slateKey]}
  slate.active = active
  lus[slateKey] = slate

  yield put({type: afterStartActions.afterStartLineups.UPDATE, payload: lus})
}

export default function* afterStartSagas() {
  yield takeLatest(
    afterStartActions.afterStartLineups.RECEIVE,
    recieveUpload
  )
  yield takeLatest(
    afterStartActions.afterStartLineups.TOGGLE,
    toggleSlate
  )
}