import * as groupActions from '../actions/groups'

const initialState = () => ({
  playerGroups: [],
  loading: false
})

const groups = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case (groupActions.groups.FETCH):
    return {
      ...state,
      loading: true
    }
    case (groupActions.groups.RECEIVE):
      return {
        ...state,
        loading: false,
        playerGroups: action.payload
    }
    default:
      return state
  }
}

// SELECTOR
export const getPlayerGroups = state => state.groups.playerGroups

export default groups
