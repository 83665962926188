import {
  CHANNEL_ON,
  CHANNEL_OFF,
  SERVER_ON,
  SERVER_OFF
} from '../actions/sockets'

const initialState = {
  listening: false,
  connected: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANNEL_ON:
      return {...state, listening: true}
    case CHANNEL_OFF:
      return {...state, listening: false}
    case SERVER_OFF:
      return {...state, connected: false}
    case SERVER_ON:
      return {...state, connected: true}
    default:
      return state
  }
}
