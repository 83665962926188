import { Card } from 'antd'
import { framed, overrideBackground, overrideBackgroundSecondary, overrideColor } from '../constants/partner'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
  background: ${framed && overrideBackground ? overrideBackground : '#fff'} !important;
  color: ${framed && overrideColor ? overrideColor : '#fff'} !important;

  .ant-checkbox-wrapper, h1, h2, h3, h4, p {
    color: ${framed && overrideColor ? overrideColor : '#fff'} !important;
  }
`
export const StyledCardSecondary = styled(Card)`
  background: ${framed && overrideBackgroundSecondary ? overrideBackgroundSecondary : '#fff'} !important;
  color: ${framed && overrideColor ? overrideColor : '#fff'} !important;

  .ant-checkbox-wrapper, h1, h2, h3, h4, p {
    color: ${framed && overrideColor ? overrideColor : '#fff'} !important;
  }
`

export default StyledCard
