import { Table, Tooltip, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import {
  HEAT_MAP_POSITIVE_COLOR_SCALE,
  HEAT_MAP_NEGATIVE_COLOR_SCALE,
  getColorForValue
} from './utils/color'

// Use text input instead of number so that it can key off of empty string on erased user input
const ExpInput = styled(Input)`
  width: 60px;
`

const StyledTable = styled(Table)`
  border-radius: 3px;

  .ant-table-tbody > tr > td {
    font-size: 12px;
  }

  tr.ant-table-row-level-0:hover > td {
    background: #41464f !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }

  td {
    &.blurry-text {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);

      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;

      /*
       Introduced in IE 10.
       See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;
    }
  }
`
const RedValue = styled.span`
  color: red;
`

const _onChangeMin = (row, e, _onChange) => {
  let MinExp =  e.target.value
  if (MinExp === ".")
    MinExp = "0."
  const _row = {
    ...row,
    MinExp
  }
  _onChange(_row)
}

const _onChangeMax = (row, e, _onChange) => {
  let MaxExp =  e.target.value
  if (MaxExp === ".")
    MaxExp = "0."
  const _row = {
    ...row,
    MaxExp
  }
  _onChange(_row)
}

const ExposureStatsCols = (numLUs, permissioned, onExposureChange) => {
  const permClassName = permissioned ? '' : 'blurry-text'

  return ([
      {
        title: 'Rank',
        dataIndex: 'userRankPos',
        key: 'userRankPos',
        width: '55px'
      },
      {
        title: 'Player',
        dataIndex: 'Name',
        key: 'player',
        width: '20%',
        render: (name, row) => {
          let n = name
          if (row.RosterPosition && row.RosterPosition === 'CPT')
            n += ' (C)'
          return n
        }
      },
      {
        title: 'Pos',
        dataIndex: 'Position',
        key: 'pos',
        width: '50px'
      },
      {
        title: 'Salary',
        dataIndex: 'Salary',
        sorter: (a, b) => Number(b.Salary) - Number(a.Salary),
        key: 'salary',
        width: '75px'
      },
      {
        title: 'Min Exp',
        dataIndex: 'MinExp',
        width: '100px',
        key: 'minExp',
        render: (text, row) => {
          return (
            <ExpInput onChange={(e) => {_onChangeMin(row, e, onExposureChange)}} value={Number(text) > 0 ? text : ''} placeholder={0} />
          )
        }
      },
      {
        title: 'Max Exp',
        dataIndex: 'MaxExp',
        width: '100px',
        key: 'maxExp',
        render: (text, row) => {
          return (
            <ExpInput onChange={(e) => _onChangeMax(row, e, onExposureChange)} value={Number(text) > 0 ? text : ''} placeholder={0} />
          )
        }
      },
      {
        title: 'Actual Exp',
        dataIndex: 'Exp',
        key: 'actualExposure',
        sorter: (a, b) => Number(a.Exp) - Number(b.Exp),
        defaultSortOrder: 'descend',
        render: (text, row) => {
          const _val = Number(text)
          if (numLUs * (_val / 100) < (numLUs * (row.MinExp / 100) - 1) || numLUs * (_val / 100) > (numLUs * (row.MaxExp / 100) + 1)) {
            return (
              <RedValue>{_val.toFixed(2)}</RedValue>
            )
          } else {
            return (
              _val.toFixed(2)
            )
          }
        }
      },
      {
        title: 'Proj. Own',
        dataIndex: 'ProjOwn',
        className: permClassName,
        key: 'projOwn',
        render: (v) => (
          <span>{Number(v).toFixed(2)}%</span>
        )
      },
      {
        title: 'Leverage',
        key: 'leverage',
        className: permClassName,
        sorter: (a, b) => {
          const _la = (Number(a.Exp) - Number(a.ProjOwn)).toFixed(2)
          const _lb = (Number(b.Exp) - Number(b.ProjOwn)).toFixed(2)
          return _lb - _la
        },
        render: (v) => {
          const _l = (Number(v.Exp) - Number(v.ProjOwn)).toFixed(2)
          const _hex = getColorForValue(_l)

          if (!permissioned)
            return (
              <div style={{width: '100%', textAlign: 'center'}}>{_l}%</div>
            )
          else
            return (
              <div style={{background: _hex, width: '100%', textAlign: 'center'}}>{_l}%</div>
            )
        }
      }
    ])
}


const ExposureTable = ({ numLUs, loading, data, permissioned, onExposureChange }) => {
  return (
    <StyledTable
      columns={ExposureStatsCols(numLUs, permissioned, onExposureChange)}
      dataSource={data}
      loading={loading}
      scroll={{ x: '720px', y: '300px' }}
      bordered
      size='small'
      pagination={false}
      rowKey={(_row, i) => {
        return `exposure-${String(i)}`
      }}
    />
  )
}

export default ExposureTable
