const GPP_POSITION_MULTIPLIERS = {
  dk: {
    QB: {
      multiplier: 2.4,
      add: 3,
    },
    RB: {
      multiplier: 2.1,
      add: 0,
    },
    WR: {
      multiplier: 2.1,
      add: 0,
    },
    TE: {
      multiplier: 1.9,
      add: 2,
    },
    DST: {
      multiplier: 1.8,
      add: 1.2,
    },
  },
  fd: {
    QB: {
      multiplier: 2.2,
      add: 1.5,
    },
    RB: {
      multiplier: 1.8,
      add: -2,
    },
    WR: {
      multiplier: 1.8,
      add: -2,
    },
    "WR/RB": {
      multiplier: 1.8,
      add: -2,
    },
    TE: {
      multiplier: 1.6,
      add: -2,
    },
    DST: {
      multiplier: 1.6,
      add: 0,
    },
  },
  ya: {
    QB: {
      multiplier: 350,
      add: 7.5,
    },
    RB: {
      multiplier: 280,
      add: 4,
    },
    WR: {
      multiplier: 280,
      add: 4,
    },
    TE: {
      multiplier: 260,
      add: 2.5,
    },
    DST: {
      multiplier: 260,
      add: 2,
    },
  },
}

export const getGPPValue = (position, salary, projPts, site) => {
  try {
    let gppThreshold = ((GPP_POSITION_MULTIPLIERS[site][position].multiplier * Number(salary)) / 1000) + GPP_POSITION_MULTIPLIERS[site][position].add

    return Number(projPts) - gppThreshold
  } catch (e) {
    return 0
  }
}

const SHOWDOWN_GPP_MULTIPLIERS = {
  dk: {
    CPT: {
      multiplier: .5,
      add: 7.5,
    },
    FLEX: {
      multiplier: .5,
      add: 5,
    },
  },
  fd: {
    CPT: {
      multiplier: .4,
      add: 4,
    },
    FLEX: {
      multiplier: .4,
      add: 2,
    },
  },
  ya: {
    CPT: {
      multiplier: 300,
      add: 5,
    },
    FLEX: {
      multiplier: 300,
      add: 2,
    },
  },
}

export const getShowdownGPPValue = (position, salary, projPts, site) => {
  let gppThreshold
  try {
    gppThreshold = ((SHOWDOWN_GPP_MULTIPLIERS[site][position].multiplier * Number(salary)) / 1000) + SHOWDOWN_GPP_MULTIPLIERS[site][position].add

    return Number(projPts) - gppThreshold
  } catch (e) {
    return 0
  }
}

export const getNBAGPPValue = (salary, projPts) => {
  return (Number(projPts)-((5*Number(salary)/1000) + 0))
}
