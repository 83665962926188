import React from 'react'
import { Row, Col, Skeleton } from 'antd'
import styled from 'styled-components'

import { AimOutlined } from '@ant-design/icons'

import { overridePrimary, overrideBackgroundSecondary } from '../constants/partner'


const ContainerRow = styled(Row) `
  width: 100%;
  overflow-x: scroll;
`
const ExpertCol = styled.div`
  display: inline-block;
  padding: 5px 15px 5px 15px;
  border: 1px solid ${overridePrimary};
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  margin: 13px;

  &:hover {
    background-color: ${overrideBackgroundSecondary};
  }
`

const SelectedExpertCol = styled(ExpertCol)`
  background: ${overridePrimary};
  color: white;

  &:hover {
    background: ${overridePrimary};
    color: #F5F5F5;
  }
`

const StyledAim = styled(AimOutlined)`
  margin-right: 4px;
`
const LoadingDots = styled.div`
  display: inline-block;
  padding: 5px 15px 5px 15px;
`

const CheatsheetsSelectBar = ({ userCheatsheets = [], onCheatsheetFilter, selectedCheatsheet, loading }) => {
  if (loading || userCheatsheets.length === 0) return null

  return (
    <Row>
      <h4>Expert Targets</h4>
      {
        loading ? '' : (
          userCheatsheets.map((cheatsheet, i) => {
            if (cheatsheet.id === selectedCheatsheet) {
              return <SelectedExpertCol key={`cheetsheet-${i}`} onClick={() => {onCheatsheetFilter(null)}}><StyledAim />{cheatsheet.name}</SelectedExpertCol>
            }
            return (
              <ExpertCol key={`cheetsheet-${i}`} onClick={() => {onCheatsheetFilter(cheatsheet.id)}}><StyledAim />{cheatsheet.name}</ExpertCol>
            )
          })
        )
      }
    </Row>
  )
}

export default CheatsheetsSelectBar