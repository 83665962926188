const ProjBoostCpt = 1.5

export const adjustPlayers = (players) => {
  return players.map(p => {
    p.Id = `${p.Id}|${p.RosterPosition}`

    if (p.RosterPosition === 'CPT') {
      p.ProjPts = (Number(p.ProjPts) * ProjBoostCpt).toFixed(2)
      p.Ceiling = (Number(p.Ceiling) * ProjBoostCpt).toFixed(2)
    }
    
    return p
  })
}

const csvHeadings = {
  'dk': "CPT,FLEX,FLEX,FLEX,FLEX,FLEX\n",
  'fd': "MVP - 1.5X Points,AnyFLEX,AnyFLEX,AnyFLEX,AnyFLEX\n",
  'ya': "SUPERSTAR,FLEX1,FLEX2,FLEX3,FLEX4\n"
}

export const getCSVHeadingsForSite = (site) => {
  return csvHeadings[site]
}

export const CPTDisplay = {
  'dk': 'CPT',
  'fd': 'MVP',
  'ya': 'STUD'
}
