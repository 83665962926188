import { Modal, Icon } from 'antd'
import {
  QuestionCircleOutlined
} from '@ant-design/icons'
import React, { Component } from 'react'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    text-align: center
  }
`

class LineupComparisonModal extends Component {
  state = { visible: false }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  render() {
    return (
      <div>
        <div onClick={this.showModal} >
          <QuestionCircleOutlined style={{marginLeft: '5px'}} theme="filled"/>
        </div>
        <StyledModal
          maskClosable
          title="Lineup Comparison Information"
          visible={this.state.visible}
          onCancel={(e) => {
            e.preventDefault()
            this.setState({
              visible: false
            })
          }}
          // footer={<p>There are six Milly Makers to compare for Week 12: 2017 Wk5, 2017 Wk6, 2017 Wk11, 2017 Wk15, 2018 Wk10, 2018 Wk15</p>}
          footer={<p></p>}
        >
          <p>This table compares your overall Lineup-Build to previous GPP contests with the same number of teams as the current Main slate (not as applicable to 1pm or 4pm). <br /><br />The comparisons are split into four stratifications: lineups that finished in the top 5, lineups that finished 6-50, all cashing lineups, and all losing lineups. The numbers represent the percentage that each stratifications do the associated metric. <br /><br />Past performance does not guarantee future results. Use these comparisons as a guide, not as absolute truth.</p>
        </StyledModal>
      </div>
    )
  }
}

export default LineupComparisonModal
