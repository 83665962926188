import { Table } from 'antd'
import styled from 'styled-components'

import { primaryColor } from '../constants/styles'
import { framed, overridePrimary, overrideBackground } from '../constants/partner'

export default styled(Table)`
td {
  text-align: center;
  padding: 2px !important;
  background: ${framed && overrideBackground ? overrideBackground : '#fff'} !important;
}

tr th {
  text-align: center !important;
  background: ${framed && overrideBackground ? overrideBackground : '#fff'} !important;
}

tr:first-child {
  th {
    color: white !important;
    background: ${framed ? overridePrimary : primaryColor} !important;
  }
}

tr.ant-table-row-level-0:hover > td {
  background: #41464f !important;
}

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.divide {
  border-right: 2px solid ${framed ? overridePrimary : primaryColor} !important;
}
`