import { createActions } from 'lessdux'

export const groups = {
  ...createActions('GROUPS', { withUpdate: true }),
  REMOVE: 'REMOVE_GROUP',
  REPLACE: 'REPLACE_GROUPS',
  REMOVE_ALL: 'REMOVE_ALL_GROUPS'
}

// Groups
export const fetchGroups = params => ({
  type: groups.FETCH,
  ...params
})
export const updateGroup = params => ({
  type: groups.UPDATE,
  ...params
})
export const removeGroup = params => ({
  type: groups.REMOVE,
  ...params
})
export const replaceGroups = params => ({
  type: groups.REPLACE,
  ...params
})
export const removeAllGroups = params => ({
  type: groups.REMOVE_ALL,
  ...params
})
