import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  text-align: center;
  font-size: 36px;
  margin-top: 20%;
`

const Maintenence = () => {
  return (
    <StyledDiv>
      We are down for server maintenance. We will be back up shortly!
    </StyledDiv>
  )
}

export default Maintenence
