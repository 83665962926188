import { takeLatest, put, call, select } from 'redux-saga/effects'

import * as accountActions from '../actions/account'
import { getAuth } from '../reducers/auth'

import { JWT_LOOKUP, baseURIui, partnerURI } from '../constants/api'
import { getToken } from '../utils/tokens'

function* fetchAccountInfo() {
  const _auth = yield select(getAuth)
  // if we are logged in save to db
  if (_auth.loggedin) {
    const token = localStorage.getItem(JWT_LOOKUP)
    let uri = `${baseURIui}/account-info`
    try {
      const response = yield call(fetch, uri, {
        method: 'GET',
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      let responseBody = yield call([response, response.json])
      yield put({ type: accountActions.account.RECEIVE, payload: responseBody })
    } catch (e) {}
  }
}

function* changePassword( {oldPassword, newPassword} ) {
  const _auth = yield select(getAuth)
  // if we are logged in save to db
  if (_auth.loggedin) {
    const token = localStorage.getItem(JWT_LOOKUP)
    let uri = `${baseURIui}/update-password`
    try {
      const response = yield call(fetch, uri, {
        method: 'POST',
        body: JSON.stringify({
          password: oldPassword,
          new_password: newPassword
        }),
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      let responseBody = yield call([response, response.json])
      if (responseBody.error)
        yield put(({type: accountActions.password.FAIL_UPDATE}))
      else
        yield put({ type: accountActions.password.SAVED })
    } catch (e) {
      yield put(({type: accountActions.password.FAIL_UPDATE}))
    }
  }
}

function* fetchForgotPassword({ email }) {
  let uri = `${baseURIui}/send-reset-password`
  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        email: email
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let responseBody = yield call([response, response.json])
    if (responseBody.error)
      yield put(({type: accountActions.forgotPassword.FAIL_FETCH}))
    else
      yield put({ type: accountActions.forgotPassword.RECEIVE })
  } catch (e) {
    yield put(({type: accountActions.forgotPassword.FAIL_FETCH}))
  }
}

function* fetchResetPassword({ token, password }) {
  let uri = `${baseURIui}/reset-password`
  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        password: password,
        token: token
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let responseBody = yield call([response, response.json])
    if (!responseBody.success)
      yield put(({type: accountActions.resetPassword.FAIL_FETCH}))
    else
      yield put({ type: accountActions.resetPassword.RECEIVE })
  } catch (e) {
    yield put(({type: accountActions.resetPassword.FAIL_FETCH}))
  }
}

function* fetchSubscription() {
  let responseBody = {}
  const token = localStorage.getItem(JWT_LOOKUP)
  let uri = `${partnerURI}/plan`
  try {
    const response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: token,
        'Content-Type': 'application/json'
      }
    })
    responseBody = yield call([response, response.json])
  } catch (e) {}

  // DONT OVERWRITE TOKEN
  // if (responseBody.access_token) {
  //   localStorage.setItem(JWT_LOOKUP, responseBody.access_token)
  // }
    

  yield put({ type: accountActions.subscription.RECEIVE, ...responseBody.subscription })
}

function* updateSubscription({ token, planName, promo, yearly }) {
  let responseBody = {}
  const authToken = localStorage.getItem(JWT_LOOKUP)
  let uri = `${baseURIui}/plan`
  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        token,
        planName,
        promo,
        yearly
      }),
      headers: {
        authorization: authToken,
        'Content-Type': 'application/json'
      }
    })
    responseBody = yield call([response, response.json])
    if (responseBody.error) {
      yield put({ type: accountActions.subscription.FAIL_SAVE, ...responseBody })
    } else {
      // update auth token to newest set of perms
      if (responseBody.access_token)
        localStorage.setItem(JWT_LOOKUP, responseBody.access_token)

      yield put({ type: accountActions.subscription.SAVED, ...responseBody.subscription })
    }
  } catch (e) {

  }
}

function* updateCreditCard({ newCard }) {
  let responseBody = {}
  const authToken = localStorage.getItem(JWT_LOOKUP)
  let uri = `${baseURIui}/account/update-credit-card`
  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({
        newCard
      }),
      headers: {
        authorization: authToken,
        'Content-Type': 'application/json'
      }
    })
    responseBody = yield call([response, response.json])
    if (responseBody.error) {
      yield put({ type: accountActions.subscription.FAIL_SAVE, ...responseBody })
    } else {
      yield put({ type: accountActions.subscription.SAVED, ...responseBody })
    }
  } catch (e) {

  }
}

function* checkPromoCode({ code }) {
  let responseBody = {}
  let uri = `${baseURIui}/check-promo/${code}`
  try {
    const response = yield call(fetch, uri, {
      method: 'GET'
    })
    responseBody = yield call([response, response.json])
    yield put({ type: accountActions.promoCode.RECEIVE_CHECK, ...responseBody })
  } catch (e) {

  }
}

/**
 * The root of the account saga.
 */
export default function* accountSaga() {
  yield takeLatest(
    accountActions.account.FETCH,
    fetchAccountInfo
  )
  yield takeLatest(
    accountActions.password.NEW,
    changePassword
  )
  yield takeLatest(
    accountActions.forgotPassword.FETCH,
    fetchForgotPassword
  )
  yield takeLatest(
    accountActions.resetPassword.FETCH,
    fetchResetPassword
  )
  yield takeLatest(
    accountActions.subscription.FETCH,
    fetchSubscription
  )
  yield takeLatest(
    accountActions.subscription.UPDATE,
    updateSubscription
  )
  yield takeLatest(
    accountActions.subscription.NEW_CARD,
    updateCreditCard
  )
  yield takeLatest(
    accountActions.promoCode.CHECK,
    checkPromoCode
  )
}
