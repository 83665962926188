import * as analysisActions from '../actions/analysis'

const initialState = {
  onOff: {
    loading: false,
    data: [],
    failedLoading: false,
  },
  onOffValues: {
    loading: false,
    data: [],
    players: [],
    failedLoading: false,
  },
  offList: {
    loading: false,
    date: [],
    failedLoading: false
  },
  onOffSummary: {
    loading: false,
    data: [],
    failedLoading: false
  },
  topStacks: {
    loading: false,
    data: [],
    failedLoading: false
  },
  positionCounts: {
    loading: false,
    data: {},
    failedLoading: false
  },
  playerProps: {
    loading: false,
    data: {},
    failedLoading: false
  },
  playerStats: {
    loading: false,
    data: [],
    failedLoading: false
  },
  positionRanks: {
    loading: false,
    data: [],
    failedLoading: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case (analysisActions.onOff.FETCH):
      return {
        ...state,
        onOff: {
          ...state.onOff,
          loading: true
        }
      }
    case (analysisActions.onOff.RECEIVE):
      return {
        ...state,
        ...{
          onOff: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.onOffValues.FETCH):
      return {
        ...state,
        onOffValues: {
          ...state.onOffValues,
          loading: true
        }
      }
    case (analysisActions.onOffValues.RECEIVE):
      return {
        ...state,
        ...{
          onOffValues: {
            loading: false,
            data: action.payload.values,
            players: action.payload.players,
            failedLoading: false
          }
        }
      }
    case (analysisActions.offList.FETCH):
      return {
        ...state,
        offList: {
          ...state.offList,
          loading: true
        }
      }
    case (analysisActions.offList.RECEIVE):
      return {
        ...state,
        ...{
          offList: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.onOffSummary.FETCH):
      return {
        ...state,
        onOffSummary: {
          ...state.onOffSummary,
          loading: true
        }
      }
    case (analysisActions.onOffSummary.RECEIVE):
      return {
        ...state,
        ...{
          onOffSummary: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.topStacks.FETCH):
      return {
        ...state,
        topStacks: {
          ...state.topStacks,
          loading: true
        }
      }
    case (analysisActions.topStacks.RECEIVE):
      return {
        ...state,
        ...{
          topStacks: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.positionCounts.FETCH):
      return {
        ...state,
        positionCounts: {
          ...state.positionCounts,
          loading: true
        }
      }
    case (analysisActions.positionCounts.RECEIVE):
      return {
        ...state,
        ...{
          positionCounts: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.playerProps.FETCH):
      return {
        ...state,
        playerProps: {
          ...state.positionCounts,
          loading: true
        }
      }
    case (analysisActions.playerProps.RECEIVE):
      return {
        ...state,
        ...{
          playerProps: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.playerStats.FETCH):
      return {
        ...state,
        playerStats: {
          ...state.playerStats,
          loading: true
        }
      }
    case (analysisActions.playerStats.RECEIVE):
      return {
        ...state,
        ...{
          playerStats: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (analysisActions.positionRanks.FETCH):
      return {
        ...state,
        positionRanks: {
          ...state.positionRanks,
          loading: true
        }
      }
    case (analysisActions.positionRanks.RECEIVE):
      return {
        ...state,
        ...{
          positionRanks: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    default:
      return state
  }
}
