// teamFlex2_oppFlex1 = teamStackPct * gameStackPct * force2FlexStck
// teamFlex2_oppFlex0 = teamStackPct * force2FlexStck * (1 - gameStackPct)
// teamFlex1_oppFlex1 = teamStackPct * gameStackPct * (1 - force2FlexStck)
// teamFlex1_oppFlex0 = teamStackPct - teamFlex2_oppFlex1 - teamFlex2_oppFlex0 - teamFlex1_oppFlex1
// teamFlex0_oppFlex0 = noStackPct
// teamFlex0plus_oppFlex0plus = 1 - teamStackPct - noStackPct


export const initialRow = {
  'freq': 0,
  'teamFlexMin': 0,
  'teamFlexMax': 7,
  'teamRBMin': 0,
  'teamRBMax': 7,
  'teamWRMin': 0,
  'teamWRMax': 7,
  'teamTEMin': 0,
  'teamTEMax': 7,
  'oppFlexMin': 0,
  'oppFlexMax': 7,
  'oppRBMin': 0,
  'oppRBMax': 7,
  'oppWRMin': 0,
  'oppWRMax': 7,
  'oppTEMin': 0,
  'oppTEMax': 7
}

export const getAdvancedRowsFromBasic = ({ teamStackPct, gameStackPct, force2FlexStck, noStackPct, ...args }) => {
  const rows = []
  teamStackPct = Number(teamStackPct)
  gameStackPct = Number(gameStackPct)
  force2FlexStck = Number(force2FlexStck)
  noStackPct = Number(noStackPct)

  // Frequencies
  const teamFlex2_oppFlex1 = teamStackPct * gameStackPct * force2FlexStck
  const teamFlex2_oppFlex0 = teamStackPct * force2FlexStck * (1 - gameStackPct)
  const teamFlex1_oppFlex1 = teamStackPct * gameStackPct * (1 - force2FlexStck)
  const teamFlex1_oppFlex0 = teamStackPct - teamFlex2_oppFlex1 - teamFlex2_oppFlex0 - teamFlex1_oppFlex1
  const teamFlex0_oppFlex0 = noStackPct
  const teamFlex0plus_oppFlex0plus = 1 - teamStackPct - noStackPct

  // base (no stacks or forced naked)
  if (teamFlex0plus_oppFlex0plus) {
    const _newRow = { ...initialRow }
    _newRow.freq = teamFlex0plus_oppFlex0plus
    _newRow.disabled = true
    rows.push(_newRow)
  }

  // naked
  if (teamFlex0_oppFlex0) {
    const _newRow = { ...initialRow }
    _newRow.freq = teamFlex0_oppFlex0
    _newRow.teamFlexMax = 0
    _newRow.teamRBMax = 0
    _newRow.teamWRMax = 0
    _newRow.teamTEMax = 0
    _newRow.oppFlexMax = 0
    _newRow.oppRBMax = 0
    _newRow.oppWRMax = 0
    _newRow.oppTEMax = 0
    rows.push(_newRow)
  }

  // simple stack
  if (teamFlex1_oppFlex0) {
    const _newRow = { ...initialRow }
    _newRow.freq = teamFlex1_oppFlex0
    _newRow.teamFlexMin = 1
    rows.push(_newRow)
  }

  // game stack
  if (teamFlex1_oppFlex1) {
    const _newRow = { ...initialRow }
    _newRow.freq = teamFlex1_oppFlex1
    _newRow.teamFlexMin = 1
    _newRow.oppFlexMin = 1
    rows.push(_newRow)
  }

  // force 2 flex team stack
  if (teamFlex2_oppFlex0) {
    const _newRow = { ...initialRow }
    _newRow.freq = teamFlex2_oppFlex0
    _newRow.teamFlexMin = 2
    rows.push(_newRow)
  }

  // force 2 flex game stack
  if (teamFlex2_oppFlex1) {
    const _newRow = { ...initialRow }
    _newRow.freq = teamFlex2_oppFlex1
    _newRow.teamFlexMin = 2
    _newRow.oppFlexMin = 1
    rows.push(_newRow)
  }

  return rows
}

export const getEmptyStack = (specificStacks) => {
  const emptyStack = { ...initialRow }

  emptyStack.freq = 100
  specificStacks.forEach((s, i) => {
    if (i > 0)
      emptyStack.freq -= Number(s.freq) * 100
  })
  emptyStack.freq = emptyStack.freq.toFixed(0)
  emptyStack.freq = emptyStack.freq / 100

  emptyStack.disabled = true
  emptyStack.label = 'Force Nothing'

  return emptyStack
}

const DEFAULT_FREQUENCIES = [.05, .25, .30, .20, .20, 0]

export const getDefaultSpecificStacks = () => {
  const rows = []

  // empty row
  const _emptyRow = { ...initialRow }
  _emptyRow.freq = DEFAULT_FREQUENCIES[0]
  _emptyRow.disabled = true
  _emptyRow.label = 'Force Nothing'
  rows.push(_emptyRow)

  // single stack
  const _singleStack = { ...initialRow }
  _singleStack.freq = DEFAULT_FREQUENCIES[1]
  _singleStack.teamFlexMin = 1
  _singleStack.label = '1+ Tm. Stack'
  rows.push(_singleStack)

  // single game stack
  const _singleGmStack = { ...initialRow }
  _singleGmStack.freq = DEFAULT_FREQUENCIES[2]
  _singleGmStack.teamFlexMin = 1
  _singleGmStack.oppFlexMin = 1
  _singleGmStack.label = '1+ Gm. Stack'
  rows.push(_singleGmStack)

  // 2 flex stack
  const _2flexStack = { ...initialRow }
  _2flexStack.freq = DEFAULT_FREQUENCIES[3]
  _2flexStack.teamFlexMin = 2
  _2flexStack.label = '2+ Tm. Stack'
  rows.push(_2flexStack)

  // 2 flex game stack
  const _2flexGmStack = { ...initialRow }
  _2flexGmStack.freq = DEFAULT_FREQUENCIES[4]
  _2flexGmStack.teamFlexMin = 2
  _2flexGmStack.oppFlexMin = 1
  _2flexGmStack.label = '2+ Gm. Stack'
  rows.push(_2flexGmStack)

  // Force no stack
  const _forceNoStack = { ...initialRow }
  _forceNoStack.freq = DEFAULT_FREQUENCIES[5]
  _forceNoStack.teamFlexMax = 0
  _forceNoStack.teamRBMax = 0
  _forceNoStack.teamWRMax = 0
  _forceNoStack.teamTEMax = 0
  _forceNoStack.oppFlexMax = 0
  _forceNoStack.oppRBMax = 0
  _forceNoStack.oppWRMax = 0
  _forceNoStack.oppTEMax = 0
  _forceNoStack.label = 'Force No Stack'
  rows.push(_forceNoStack)

  return rows
}

export const getMaxFreq = (specificStacks, rowIndex) => {
  let totalFreq = 0
  specificStacks.forEach((s, i) => {
    if (i !== 0 && i !== rowIndex)
      totalFreq += Number(s.freq) * 100
  })
  return (100 - totalFreq) / 100
}

const primarySecondaryOptions = {
  'dk': [
    {
      'PrimaryStack': 5,
      'SecondaryStack': 3,
      'freq': 0
    },
    {
      'PrimaryStack': 5,
      'SecondaryStack': 2,
      'freq': 0
    },
    {
      'PrimaryStack': 5,
      'SecondaryStack': 0,
      'freq': 0
    },
    {
      'PrimaryStack': 4,
      'SecondaryStack': 4,
      'freq': 0
    },
    {
      'PrimaryStack': 4,
      'SecondaryStack': 0,
      'freq': 0
    },
    {
      'PrimaryStack': 3,
      'SecondaryStack': 0,
      'freq': 0
    },
  ],
  'fd': [
    {
      'PrimaryStack': 4,
      'SecondaryStack': 4,
      'freq': 0
    },
    {
      'PrimaryStack': 4,
      'SecondaryStack': 0,
      'freq': 0
    },
    {
      'PrimaryStack': 3,
      'SecondaryStack': 0,
      'freq': 0
    },
  ],
}

export const getDefaultSpecificStacksMLB = (site) => {
  return primarySecondaryOptions[site]
}
