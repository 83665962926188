import { Table, Tooltip } from 'antd'
import {
  LockOutlined
} from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { nflTeamNames as TeamToAbbrev } from '../../utils/team-name-to-abbrev'
import { getGPPValue, getShowdownGPPValue } from '../../utils/gpp-value'

const StyledTable = styled(Table)`
  td {
    text-align: center;
    padding: 2px !important;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: #1d3557 !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`
const PropsKeyToDisplay = {
  'MostPoints': 'Most Points',
  'MostRecYds': 'Most Rec. Yards',
  'MostRushYds': 'Most Rush Yards',
  'MostPassYds': 'Most Pass Yards',
}

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const playerCols = ([
  {
    title: 'Name',
    key: 'name',
    render: (row) => {
      return (
        row.Name
      )
    }
  },
  {
    title: 'Salary',
    key: 'salary',
    width: '100px',
    render: (row) => {
      return row.salary
    }
  },
  {
    title: 'Pos.',
    key: 'position',
    width: '80px',
    render: (row) => {
      return row.Position === 'NA' ? '-' : row.Position
    }
  },
])

const playerPropsCols = (propsKey, permissions) =>  ([
  {
    title: 'Team',
    key: 'team',
    width: '120px',
    render: (row) => {
      return row.Team === 'NA' ? '-' : row.Team
    }
  },
  {
    title: `Odds to have ${PropsKeyToDisplay[propsKey]}`,
    key: 'oddsAmerican',
    dataIndex: 'oddsAmerican',
    render: (v) => {
      return `${v[0] === '-' ? '' : '+'}${v}`
    }
  },
  {
    title: `${propsKey === 'MostPoints' ? 'QB Proj. Own' : 'Main Slate Proj. Own'}`,
    key: 'projOwn',
    dataIndex: 'projOwn',
    render: (v) => {
      if (permissions && permissions.opt.nfl.ownership) {
        return `${v}`
      } else {
        return (<Lock />)
      }
    }
  }
])

const MinWidth = 1526

const PropsTable = ({ loading, data = [], propsKey, permissions }) => {
  const _setWidths = window.innerWidth > MinWidth

  let cols = [
    ...(propsKey === 'MostPoints' ? [] : playerCols),
    ...playerPropsCols(propsKey, permissions)
  ]

  return (
    <StyledTable
      columns={cols}
      dataSource={data}
      pagination={{ pageSize: 100 }}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => `${String(_row.Name||_row.Team)}`}
      scroll={{ y: 700, x: 500 }}
    />
  )
}

export default PropsTable
