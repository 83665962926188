import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import Initializer from './initializer'
import LineupGenerator from '../containers/lineup-generator'
import Maintenence from '../containers/home/maintenance'


import { MainSlate } from '../constants/slates'

import './app.css'

const maintenance = false

const App = ({ store }) => (
  <Provider store={store}>
    <Initializer>
    <BrowserRouter>
      <div id="router-root">
        <Helmet>
          <title>DFSForecast</title>
        </Helmet>
          <div id="scroll-root">
              {
                maintenance ? (
                  <Switch>
                    <Route exact path="*" component={Maintenence} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/" render={() => {
                      const searchParams = new URLSearchParams(window.location.search)
                      let queryString = ''
                      for (const [key, value] of searchParams.entries()) {
                        // don't include slate info
                        if (key !== 'date' && key !== 'season') {
                          queryString += `${key}=${value}&`
                        }
                      }
                      return <Redirect to={`/optimizer/nfl/dk/${MainSlate}?${queryString}`} />
                    }} />
                    <Route exact path="/optimizer/:sport/:site/:slate" component={LineupGenerator} />
                    <Route exact path="/optimizer/:contestType/:sport/:site/:slate" component={LineupGenerator} />
                    <Route exact path="/optimizer" render={() => (
                        <Redirect to="/" />
                      )} />
                    <Route exact path="/optimizer/nfl/dk" render={() => (
                        <Redirect to={`/optimizer/nfl/dk/${MainSlate}`} />
                      )} />
                    <Route exact path="/optimizer/nfl/fd" render={() => (
                        <Redirect to={`/optimizer/nfl/fd/${MainSlate}`} />
                      )} />
                  </Switch>
                )
              }
          </div>
      </div>
    </BrowserRouter>
  </Initializer>
  </Provider>
)

App.propTypes = {
  // State
  store: PropTypes.shape({}).isRequired,

  // Testing
  testElement: PropTypes.element
}

App.defaultProps = {
  // Testing
  testElement: null
}

export default App
