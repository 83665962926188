import { createActions } from 'lessdux'

export const customPresets = {
  ...createActions('CUSTOM_PRESETS', { withUpdate: true }),
  UPDATE_COMPLETE: 'CUSTOM_PRESETS_UPDATE_COMPLETE',
  UPDATE_FAILED: 'CUSTOM_PRESETS_UPDATE_FAILED',
}

export const cheatsheets = {
  ...createActions('CHEATSHEETS', { withUpdate: true }),
  UPDATE_COMPLETE: 'CHEATSHEETS_UPDATE_COMPLETE',
  UPDATE_FAILED: 'CHEATSHEETS_UPDATE_FAILED',
}

export const updateCustomPresets = payload => ({
  type: customPresets.UPDATE,
  ...payload
})

export const fetchCustomPresets = payload => ({
  type: customPresets.FETCH,
  ...payload
})

export const updateCheatsheets = payload => ({
  type: cheatsheets.UPDATE,
  ...payload
})

export const fetchCheatsheets = payload => ({
  type: cheatsheets.FETCH,
  ...payload
})
