import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import MillyEdgeWeek17 from '../../../assets/images/article-headings/mm-edge-week-17.jpg'
import MillyEdgeWeek18 from '../../../assets/images/article-headings/mm-edge-week-18.jpg'
import AdvancedStacking from  '../../../assets/images/article-headings/advanced-stacking.jpg'
import DFSForecastBack from '../../../assets/images/article-headings/dfsforecast-back-2021.png'
import BracketOwnership from '../../../assets/images/article-headings/bracket-ownership.png'

const WelcomeBox = styled.div`
  position: relative;
  text-align: center;
  background: #fff;
  min-height: 450px;
`

const ArticleBoxBig = styled.div`
  border-radius: 5px;

  img {
    width: 80%;
    box-shadow: 10px 10px 10px black;
    cursor: pointer;

    &:hover {
      border: 4px solid #70a2c1;
      box-shadow: 10px 10px 10px black;
    }
  }
`
const ArticleBoxSmall = styled.div`
  margin: 5px 0px;

  img {
    width: 76%;
    box-shadow: 10px 10px 10px black;
    cursor: pointer;

    &:hover {
      border: 4px solid #70a2c1;
      box-shadow: 10px 10px 10px black;
    }
  }
`

const ColSize = 6

export default () => {
    return (
        <WelcomeBox>
            <div>
                <Row style={{paddingTop: '40px', paddingBottom: '40px'}}>
                    <Col lg={ColSize} md={24} sm={24} xs={24}>
                        <ArticleBoxSmall>
                        <a href="https://blog.dfsforecast.com/draftkings-milly-maker-review-week-18/">
                            <img src={MillyEdgeWeek18} alt={'Milly Maker Edges Week 18'}/>
                        </a>
                    </ArticleBoxSmall>
                    </Col>
                      <Col lg={ColSize} md={24} sm={12} xs={12}>
                        <ArticleBoxSmall>
                          <a href="https://blog.dfsforecast.com/advanced-stacking/">
                            <img src={AdvancedStacking} alt={'advanced stacking'}/>
                          </a>
                        </ArticleBoxSmall>
                      </Col>
                      <Col lg={ColSize} md={24} sm={12} xs={12}>
                        <ArticleBoxSmall>
                          <a href="https://blog.dfsforecast.com/draftkings-milly-maker-review-week-17/">
                            <img src={MillyEdgeWeek17} alt={'Milly Maker Edges Week 17'}/>
                          </a>
                        </ArticleBoxSmall>
                      </Col>
                      <Col lg={ColSize} md={24} sm={12} xs={12} style={{marginTop: '10px'}}>
                        <ArticleBoxSmall>
                          <a href="https://blog.dfsforecast.com/bracket-ownership-a-simple-way-to-gain-an-edge/">
                            <img src={BracketOwnership} alt={'dfsforecast-is-back'}/>
                          </a>
                        </ArticleBoxSmall>
                      </Col>
                </Row>
            </div>
          </WelcomeBox>
    )
}