export const teamNameCorrections = {
  'denver nuggets': 'Nuggets',
  "los angeles lakers": 'Lakers',
  'golden state warriors': 'Warriors',
  'phoenix suns': 'Suns',
  'portland trail blazers': 'Blazers',
  'philadelphia 76ers': '76ers',
  'milwaukee bucks': 'Bucks',
  'chicago bulls': 'Bulls',
  'cleveland cavaliers': 'Cavaliers',
  'boston celtics': 'Celtics',
  'la clippers': 'Clippers',
  'memphis grizzlies': 'Grizzlies',
  'atlanta hawks': 'Hawks',
  'miami heat': 'Heat',
  'charlotte hornets': 'Hornets',
  'utah jazz': 'Jazz',
  'sacramento kings': 'Kings',
  'new york knicks': 'Knicks',
  'orlando magic': 'Magic',
  'dallas mavericks': 'Mavericks',
  'brooklyn nets': 'Nets',
  'indiana pacers': 'Pacers',
  'new orleans pelicans': 'Pelicans',
  'detroit pistons': 'Pistons',
  'toronto raptors': 'Raptors',
  'houston rockets': 'Rockets',
  'san antonio spurs': 'Spurs',
  'oklahoma city thunder': 'Thunder',
  'minnesota timberwolves': 'Timberwolves',
  'washington wizards': 'Wizards',
}