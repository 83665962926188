import * as accountActions from '../actions/account'

const initialState = {
  loading: false,
  failedLoading: false,
  data: {},
  password: {
    updating: false,
    failedUpdating: false
  },
  forgotPassword: {
    updating: false,
    failedUpdating: false
  },
  resetPassword: {
    updating: false,
    failedUpdating: false
  },
  subscription: {
    planName: null,
    cancelAt: null,
    last4: null,
    brand: null,
    permissions: null,
    trialEnd: null,
    updating: false,
    failedUpdating: false,
    loading: false,
    failedLoading: false
  },
  promoCode: {
    valid: null
  }
}

const account = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case (accountActions.account.FETCH):
      return {
        ...state,
        failedLoading: false,
        loading: true
      }
    case (accountActions.account.RECEIVE):
      return {
        ...state,
        failedLoading: false,
        loading: false,
        data: action.payload
      }
    case (accountActions.password.NEW):
      return {
        ...state,
        password: {
          updating: true,
          failedUpdating: false
        }
      }
    case (accountActions.password.SAVED):
      return {
        ...state,
        password: {
          updating: false,
          failedUpdating: false
        }
      }
    case (accountActions.password.FAIL_UPDATE):
      return {
        ...state,
        password: {
          updating: false,
          failedUpdating: true
        }
      }
    case (accountActions.resetPassword.FETCH):
      return {
        ...state,
        resetPassword: {
          updating: true,
          failedUpdating: false
        }
      }
    case (accountActions.resetPassword.RECEIVE):
      return {
        ...state,
        resetPassword: {
          updating: false,
          failedUpdating: false
        }
      }
    case (accountActions.resetPassword.FAIL_FETCH):
      return {
        ...state,
        resetPassword: {
          updating: false,
          failedUpdating: true
        }
      }
    case (accountActions.forgotPassword.FETCH):
      return {
        ...state,
        forgotPassword: {
          updating: true,
          failedUpdating: false
        }
      }
    case (accountActions.forgotPassword.RECEIVE):
      return {
        ...state,
        forgotPassword: {
          updating: false,
          failedUpdating: false
        }
      }
    case (accountActions.forgotPassword.FAIL_FETCH):
      return {
        ...state,
        forgotPassword: {
          updating: false,
          failedUpdating: true
        }
      }
    case (accountActions.subscription.FETCH):
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: true,
          failedLoading: false,
          error: null
        }
      }
    case (accountActions.subscription.RECEIVE):
      return {
        ...state,
        subscription: {
          ...state.subscription,
          planName: action.plan_name,
          permissions: action.permissions,
          cancelAt: action.plan_ends,
          currentPeriodEnd: action.current_period_end,
          trialEnd: action.trial_end,
          last4: action.last4,
          brand: action.brand,
          loading: false,
          failedLoading: false,
          yearly: action.yearly,
          error: null,
          price: action.price || 0
        }
      }
    case (accountActions.subscription.UPDATE):
      return {
        ...state,
        subscription: {
          ...state.subscription,
          updating: true,
          failedUpdating: false,
          error: null
        }
      }
    case (accountActions.subscription.SAVED):
      return {
        ...state,
        subscription: {
          ...state.subscription,
          planName: action.plan_name,
          permissions: action.permissions,
          cancelAt: action.plan_ends,
          last4: action.last4,
          brand: action.brand,
          updating: false,
          failedUpdating: false,
          error: null
        }
      }
    case (accountActions.subscription.FAIL_SAVE):
      return {
        ...state,
        subscription: {
          ...state.subscription,
          updating: false,
          failedUpdating: true,
          error: action.error
        }
      }
    case (accountActions.subscription.NEW_CARD):
      return {
        ...state,
        subscription: {
          ...state.subscription,
          updating: true,
          failedUpdating: false,
        }
      }
    case (accountActions.promoCode.RECEIVE_CHECK):
      return {
        ...state,
        promoCode: {
          valid: action.valid
        }
      }
    default:
      return state
  }
}

export default account
