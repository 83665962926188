// Used to check if player table has been altered
export const isPlayerTableDefault = (players) => {
  let d = true
  players.forEach(p => {
    // Have adjusted Min or Max or removed from table
    if (Number(p.MinExp) !== 0 || Number(p.MaxExp) !== 100) {
      d = false
      return
    }
    // Have changed Proj or Own
    if (p.UserProj || p.UserOwn) {
      d = false
      return
    }
  })

  return d
}