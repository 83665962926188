import { Table, Tooltip } from 'antd'
import {
  LockOutlined
} from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { nflTeamNames as TeamToAbbrev } from '../../utils/team-name-to-abbrev'
import { getGPPValue, getShowdownGPPValue } from '../../utils/gpp-value'

import { primaryColor } from '../../constants/styles'
import { overridePrimary, framed } from '../../constants/partner'

const StyledTable = styled(Table)`
  td {
    text-align: center;
    padding: 2px !important;
  }

  tr th {
    text-align: center !important;
  }

  tr:first-child {
    th {
      color: white !important;
      background: ${framed ? overridePrimary : primaryColor} !important;
    }
  }

  tr.ant-table-row-level-0:hover > td {
    background: #e6f7ff !important;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  }

  .divide {
    border-right: 2px solid #4004A3 !important;
  }
`

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const playerDetailsCols =  (setWidths, showdown) => {
  const children = [
    {
      title: 'Player Name',
      dataIndex: 'Name',
      key: 'playerName',
      width: 160,
    },
    {
      title: 'Team',
      dataIndex: 'TeamAbbrev',
      key: 'teamAbbrev',
      width: setWidths ? 80 : undefined,
      render: (text, row) => {
        return (
          <img src={require(`../../assets/images/nfl/teamlogos/${text}.png`).default} style={{width: '28px'}} alt={TeamToAbbrev[text]}/>
        )
      }
    },
    {
      title: 'Pos',
      dataIndex: 'Position',
      key: 'position',
      width: setWidths ? 60 : undefined,
    }
  ]

  if (showdown) {
    children.push({
      title: 'Roster Position',
      dataIndex: 'RosterPosition',
      key: 'roster-position',
      width: 120,
    })
  }

  children.push({
    title: 'Salary',
    dataIndex: 'Salary',
    key: 'salary',
    defaultSortOrder: 'descend',
    sorter: (a, b) => Number(a.Salary - b.Salary),
    className: 'divide',
    width: 100,
  })

  return ({
    title: 'Player Details',
    key: 'playerDetails',
    children
  })
}

const playerProjectionCols = (permissions, site, showdown) => ({
  title: 'Player Projections',
  key: 'playerProjections',
  children: [
    {
      title: (
        <Tooltip placement="top" title={"DFSForecast’s projected point total"}>
          <span>Proj Pts</span>
        </Tooltip>
      ),
      key: 'dKPts',
      sorter: (a, b) => Number(a.HouseProjPts || a.ProjPts) - Number(b.HouseProjPts || b.ProjPts),
      render: (_, row) => {
        if (permissions && permissions.opt.nfl.projections) {
          let projPts = row.ProjPts

          return projPts
        } else {
          return <Lock />
        }
      }
    },
    {
      title: 'Proj Own',
      dataIndex: 'ProjOwn',
      key: 'projOwn',
      className: 'divide',
      sorter: (a, b) => Number(a.ProjOwn) - Number(b.ProjOwn),
      render: (_, row) => {
        if (permissions && permissions.opt.nfl.ownership) {
          return row.HouseOwn && row.UserOwn ? (<span style={{color: "#9a9a9a"}}>{row.HouseOwn}</span>) : row.ProjOwn
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected points / Salary * 1000"}>
          <span>Proj Value</span>
        </Tooltip>
      ),
      key: 'projval',
      sorter: (a, b) => (Number(a.ProjPts) / Number(a.Salary) * 1000) - (Number(b.ProjPts) / Number(b.Salary) * 1000),
      render: (_, row) => {
        let multiplier = 1000
        if (site === 'ya')
          multiplier = 10

        if (permissions && permissions.opt.nfl.projections) {
          return (
            (Number(row.ProjPts) / Number(row.Salary) * multiplier).toFixed(3)
          )
        } else {
          return (Number(row.UserProj || 0) / Number(row.Salary) * multiplier).toFixed(3)
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Combined GPP value metric"}>
          <span>GPP Value</span>
        </Tooltip>
      ),
      sorter: (a, b) => {
        if (showdown) {
          return getShowdownGPPValue(b.RosterPosition, b.Salary, b.ProjPts, site) - getShowdownGPPValue(a.RosterPosition, a.Salary, a.ProjPts, site)
        }
        else {
          return getGPPValue(b.Position, b.Salary, b.ProjPts, site) - getGPPValue(a.Position, a.Salary, a.ProjPts, site)
        }
      },
      key: 'gppVal',
      render: (row) => {
        if (permissions && permissions.opt.nfl.projections) {
          if (showdown) {
            if (!row.RosterPosition)
              return 0
            else
              return (
                getShowdownGPPValue(row.RosterPosition, row.Salary, row.ProjPts, site).toFixed(2)
              )
          } else {
            return (
              getGPPValue(row.Position, row.Salary, row.ProjPts, site).toFixed(2)
            )
          }
        } else {
          return <Lock />
        }
      }
    },
  ]
})

const MinWidth = 1526

const ProjectionsTable = ({ loading, rows = [], permissions, site, showdown }) => {
  const _setWidths = window.innerWidth > MinWidth

  const allCols = [
    playerDetailsCols(_setWidths, showdown),
    playerProjectionCols(permissions, site, showdown)
  ]

  return (
    <StyledTable
      columns={allCols}
      dataSource={rows}
      pagination={{ pageSize: 100 }}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => `${String(_row.Id)}-${_row.RosterPosition}`}
      scroll={{ y: 700, x: 1300 }}
    />
  )
}

export default ProjectionsTable
