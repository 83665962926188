import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"

import styled from "styled-components"

export const BoostText = styled.div`
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: -10px;
  z-index: 0;
  font-size: 13px;
`
export const ArrowInput = styled.div`
  margin: -5px 0px -5px 0px;
`

const UP_COLORS = ['#4BD031', '#42CF26', '#39CE1B','#2ECF0E', '#23CF01']
export const UpArrow = styled(CaretUpOutlined)`
  color: ${(props) => {
    const val = Number(props.value)
    if (val < 1) return '#999999 !important'
    else return `${UP_COLORS[val - 1]} !important`
  }};
  cursor: pointer;
  z-index: 2;
  padding: 5px;
`

const DOWN_COLORS = ['#D25F5F', '#D14444', '#D02F2F','#D01818', '#CF0101']
export const DownArrow = styled(CaretDownOutlined)`
  color: ${(props) => {
    const val = Number(props.value)
    if (val > -1) return '#999999 !important'
    else return `${DOWN_COLORS[(val * -1) - 1]} !important`
  }};
  cursor: pointer;
  z-index: 2;
  padding: 5px;
`