import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Menu, Skeleton } from 'antd'
import { Link } from 'react-router-dom'

import ArticleContainer from './article-container'
import VertMenu, { getItem } from './vert-menu'

import NFLLogo from '../../../assets/images/nfllogo.png'
import NBALogo from '../../../assets/images/nba-logo.png'

const MenuContainer = styled.div`
    padding: 60px 5% 50px 5%;
    min-height: 550px;
    color: #202a37;
    overflow-x: hidden;
`
const ShadowContainer = styled.div`
    box-shadow: 2px 2px 10px #9e9e9e;
`
const StyledMenu = styled(Menu)`
    font-size: 30px;
`
const LogoImg = styled.img`
    height: 20px;
    margin: 0px 5px 5px 5px;
`
const Heading = styled.div`
    font-size: 24px;
    color: #1d3557;
`
const SubText = styled.div`
    font-size: 18px;
    color: #1d3557;
    margin-top: 25px;
`
const SubMenuRow = styled(Row)`
    min-height: 450px;
    padding: 35px;
    background: #fff;
`
const ActionButton = styled.div`
    background: transparent;
    border-radius: 10px;
    color: #4ab2ed;
    border: 1px solid #4ab2ed;
    padding: 15px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    width: 180px;

    &:hover {
        border: 1px solid #4ab2ed;
    }

    @media (max-width: 991px) {
        margin-bottom: 50px;
    }
`

const LineupBuilderMenu = ({}) => {
    return (
        <SubMenuRow>
            <Col lg={24}>
                <Heading>Winning Lineups from Previous Winners.</Heading>
                <SubText style={{marginTop: '50px'}}>
                    Our unique Lineup Builder includes Presets that instantly tailor your settings based on the characteristics and strategies of previous Milly Maker winners. You can also customize your build with tons of settings and instantly check your potential to win using our post-build analysis.
                </SubText>
                <Row style={{marginTop: '100px'}}>
                    <Col lg={5} md={12} style={{marginRight: '5px'}}>
                        <Link to="/optimizer/classic/nfl/dk/Main">
                            <ActionButton><LogoImg src={NFLLogo} /> NFL</ActionButton>
                        </Link>
                    </Col>
                    <Col lg={5} md={12}>
                        <Link to="/optimizer/classic/nba/dk/Main">
                            <ActionButton><LogoImg src={NBALogo} />  NBA</ActionButton>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </SubMenuRow>
    )
}

const OnOffMenu = ({}) => {
    return (
        <SubMenuRow>
            <Col lg={24}>
                <Heading>An NBA On/Off tool that gives you the full picture.</Heading>
                <SubText style={{marginTop: '50px'}}>
                    We analyzed active vs. inactive data and calculated which players have the most value in the current slate. This tool answers a vital question for GPP contests: when a player is ruled out, which of their teammates are the most valuable in the current slate given their salary and projected minutes?
                </SubText>
                <SubText>
                    We give you a slate-specific DFSForecast value comparison all in one place. 
                </SubText>
                <Row style={{marginTop: '60px'}}>
                    <Col lg={5} md={12} style={{marginRight: '5px'}}>
                        <Link to="/analysis/nba/dk/Main/on-off">
                            <ActionButton>On/Off Tool</ActionButton>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </SubMenuRow>
        
    )
}

const Analysis = ({}) => {
    return (
        <SubMenuRow>
            <Col lg={24}>
                <Heading>Next Generation Tools</Heading>
                <SubText style={{marginTop: '50px'}}>
                    We analyzed the data for you. From our Stack Simulator Tools to Milly Maker Leverage scores and Player Props, we built every tool to provide your edge from the rest of the field.
                </SubText>
                <Row style={{marginTop: '100px'}}>
                    <Col lg={5} md={12} style={{marginRight: '5px'}}>
                        <Link to="/analysis/nfl/dk/Main/stacks">
                            <ActionButton>NFL DK Stacks</ActionButton>
                        </Link>
                    </Col>
                    <Col lg={5} md={12} style={{marginRight: '5px'}}>
                        <Link to="/analysis/nfl/fd/Main/stacks">
                            <ActionButton>NFL FD Stacks</ActionButton>
                        </Link>
                    </Col>
                    <Col lg={5} md={12} style={{marginRight: '5px'}}>
                        <Link to="/analysis/nfl/ya/Main/stacks">
                            <ActionButton>NFL YA Stacks</ActionButton>
                        </Link>
                    </Col>
                    <Col lg={5} md={12} style={{marginRight: '5px'}}>
                        <Link to="/props">
                            <ActionButton>Props</ActionButton>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </SubMenuRow>
        
    )
}

const MenuSection = ({ }) => {
    const [ menuItem, setMenuItem ] = useState('lineup-builder')

    const MenuItems = [
        {
            key: 'lineup-builder',
            text: (
                <span>Lineup Builders</span>),
            body: (<LineupBuilderMenu  />)
        },
        {
            key: 'on-off',
            text: (
                <span>On/Off</span>
            ),
            body: (<OnOffMenu />)
        },
        {
            key: 'analytics',
            text: 'Analytics',
            body: (<Analysis />)
        },
    ]

    const selectedBody = MenuItems.filter(i => i.key === menuItem)[0].body

    return (
        <MenuContainer>
            <ShadowContainer>
                <StyledMenu 
                    onClick={(e) => setMenuItem(e.key)} selectedKeys={[menuItem]} mode="horizontal"
                    style={{ fontSize: '20px'}}
                >
                    {
                        MenuItems.map((n) => {
                            return (
                                <Menu.Item key={n.key}>{n.text}</Menu.Item>
                            )
                        })
                    }
                </StyledMenu>
                {
                    selectedBody
                }   
            </ShadowContainer>
        </MenuContainer>
    )
}

export default MenuSection