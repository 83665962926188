import { createActions } from 'lessdux'

export const onOff = {
  ...createActions('ON_OFF_TABLE')
}
export const onOffValues = {
  ...createActions('ON_OFF_VALUES')
}
export const offList = {
  ...createActions('OFF_LIST')
}
export const topStacks = createActions('TOP_STACKS')
export const onOffSummary = createActions('ON_OFF_SUMMARY_TABLE')
export const positionCounts = createActions('POSITION_COUNTS')
export const playerProps = createActions('PLAYER_PROPS')
export const playerStats = createActions('PLAYER_STATS')
export const positionRanks = createActions('POSITION_RANKS')

export const fetchOnOff = ({ players, team, playerTable }) => ({
  type: onOff.FETCH,
  players,
  team,
  playerTable
})

export const fetchOnOffValues = ({ playerTable, updatedAt, offList, site, minMinutes }) => ({
  type: onOffValues.FETCH,
  playerTable,
  updatedAt,
  offList,
  site,
  minMinutes
})

export const fetchOffList = ({ playerTable }) => ({
  type: offList.FETCH,
  playerTable
})

export const fetchTopStacks = ({ site, season, week }) => ({
  type: topStacks.FETCH,
  site,
  season,
  week
})

export const fetchPositionCounts = ({ numTeams, numLUs }) => ({
  type: positionCounts.FETCH,
  numLUs,
  numTeams
})

export const fetchPlayerProps = () => ({
  type: playerProps.FETCH
})

export const fetchPlayerStats = ({ name, position }) => ({
  type: playerStats.FETCH,
  name,
  position
})

export const fetchPositionRanks = ({ slateSize, numLUs }) => ({
  type: positionRanks.FETCH,
  slateSize,
  numLUs
})
