import { Row, Col } from 'antd'
import React from 'react'
import styled from 'styled-components'

import TOS from '../../assets/agreements/tos.pdf'
import Privacy from '../../assets/agreements/privacy.pdf'

const FooterContainer = styled.div`
  color: #fff;
  height: 60px;
  background: #1d3557;
  bottom: 0;
  padding: 18px 5%;
  z-index: 5;

  @media (max-width: 992px) {
    font-size: 10px;
  }

  @media (max-width: 600px) {
    font-size: 8px;
  }
`
const Terms = styled.a`
  color: white;
  text-decoration: none;
  margin: 0px 8px;
`
const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 0px 4px;
  font-size: 12px;

  @media (max-width: 992px) {
    font-size: 10px;
  }

  @media (max-width: 700px) {
    font-size: 8px;
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <Row>
        <Col lg={8} sm={8} xs={8}>
          DFS Forecast 2022
          <Terms href={TOS}>Terms of Service</Terms>
          <Terms href={Privacy}>Privacy</Terms>
        </Col>
        <Col lg={8} sm={8} xs={8} style={{textAlign: 'center'}}>
          <StyledLink href="/plans">Plans</StyledLink>
          <StyledLink href="https://blog.dfsforecast.com/">Articles</StyledLink>
          <StyledLink href="/analysis/nfl/dk/Main/stacks">Analysis</StyledLink>
          <StyledLink href="/optimizer/nfl/dk/Main">Optimizer</StyledLink>
          <StyledLink href="/account">Account</StyledLink>
        </Col>
        <Col lg={8} sm={8} xs={8} style={{textAlign: 'right'}}>
          <b>Need Help?</b> contact<code>&#64;</code>dfsforecast.com
        </Col>
      </Row>
    </FooterContainer>
  )
}

export default Footer
