import { Row, Col, Menu, Select, Button, Spin } from 'antd'
import {
  CloudDownloadOutlined
} from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import * as lineupActions from '../../actions/lineup'
import * as accountActions from '../../actions/account'
import { week, season } from '../../utils/nfl-week'
import LastUpdated from '../../components/last-updated'

import { downloadCSV } from '../../utils/csv'

import ProjectionsTable from './projections-table'

const ProjectionsContainer = styled.div`
  padding: 100px 2% 76px;
  min-height: 100vh;
  border-radius: 10px;
`

const downloadProjections = (players, slate, site, showdown) => {
  let csv = ''
  if (showdown) {
    csv = 'Id,Name,RosterPosition,Team,Pos,ProjPts,ProjOwn\n'

    players.forEach(p => {
      let _id = p.Id
      _id = _id.split("|")[0]

      csv += `${_id},${p.Name},${p.RosterPosition},${p.TeamAbbrev},${p.Position},${p.ProjPts},${p.ProjOwn}\n`
    })
  } else {
    csv = 'Id,Name,Team,Pos,ProjPts,ProjOwn\n'

    players.forEach(p => {
      csv += `${p.Id},${p.Name},${p.TeamAbbrev},${p.Position},${p.ProjPts},${p.ProjOwn}\n`
    })
  }

  downloadCSV(csv, `projections-nfl-${site}-${slate}-week${week}-${season}`)
}

const Projections = ({ players, slates, subscription, fetchPlayers, fetchSubscription, fetchSlates, match }) => {
  const [ slate, setSlate ] = useState("Main")
  const [ showdown, setShowdown ] = useState(false)
  const [ projType, setProjType ] = useState("ProjPts")

  useEffect(() => {
    fetchSubscription()
    fetchSlates({site: match.params.site, week, season })
  }, [])

  useEffect(() => {
    refresh()
  }, [ slate ])

  const refresh = () => {
    fetchPlayers({slate, site: match.params.site, sport: match.params.sport, showdown})
  }

  const sport = match.params.sport
  const _slates = slates.data || []

  let downloadable = subscription.permissions && subscription.permissions.opt.nfl.ownership

  return (
    <ProjectionsContainer>
      <Menu selectedKeys={[match.params.site]} mode="horizontal">
        <Menu.Item key="dk">
          <a href="/projections/nfl/dk">DraftKings</a>
        </Menu.Item>
        <Menu.Item key="fd">
          <a href="/projections/nfl/fd">Fanduel</a>
        </Menu.Item>
        <Menu.Item key="ya">
          <a href="/projections/nfl/ya">Yahoo</a>
        </Menu.Item>
      </Menu>
      <div style={{margin: '20px 0px'}}>
        <Row>
          <Col lg={8}>
            <h4 style={{display: 'inline-block', margin: '0px 10px'}}>Slate</h4>
            <Select
              style={{width: '200px', display: 'inline-block'}}
              onChange={(typeSlate) => {
                const [contestType, slate] = typeSlate.split("|")
                if (contestType === "showdown") {
                  if (!showdown)
                    setShowdown(true)
                } else {
                  if (showdown)
                    setShowdown(false)
                }

                setSlate(slate)
              }}
              value={slate}
            >
              {
                _slates &&
                _slates.classic &&
                _slates.classic.map(_slate => {
                  let _displayName = _slate

                  return (
                    <Select.Option key={_slate} value={`classic|${_slate}`}>{_displayName}</Select.Option>
                  )
                })
              }
              {
                _slates &&
                _slates.showdown &&
                _slates.showdown.map(_slate => {
                  let _displayName = _slate

                  return (
                    <Select.Option key={_slate} value={`showdown|${_slate}`}>{_displayName}</Select.Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col lg={8} offset={8}>
            <Button
              style={{float: 'right'}}
              onClick={() => downloadProjections(players.data, slate, match.params.site, showdown)}
              disabled={players.loading || subscription.loading || !downloadable}
            >
              <CloudDownloadOutlined />Download
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{marginTop: '30px'}}>
        <LastUpdated timestamp={players ? players.updatedAt : null} refresh={refresh} />
          <ProjectionsTable
            loading={players.loading}
            rows={players.data || []}
            permissions={subscription.permissions}
            site={match.params.site}
            showdown={showdown}
          />
      </div>
    </ProjectionsContainer>
  )
}

export default connect(
  state => ({
    players: state.lineup.players,
    subscription: state.account.subscription,
    slates: state.lineup.slates
  }),
  {
    fetchPlayers: lineupActions.fetchPlayers,
    fetchSubscription: accountActions.fetchSubscription,
    fetchSlates: lineupActions.fetchSlates,
  }
)(Projections)
