import {
  cheatsheets,
  customPresets,
} from '../actions/admin'

const initialState = {
  loading: true,
  failedLoading: false,
  errorMessage: null,
  customPresets: [],
  settingPresets: false,
  updateFailed: false,
  cheatsheets: [],
  cheatsheetsLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case customPresets.FETCH:
      return {...state, loading: true, failedLoading: false, errorMessage: null}
    case customPresets.RECEIVE:
      return {...state, loading: false, failedLoading: false, errorMessage: null, customPresets: action.payload}
    case customPresets.UPDATE:
      return {...state, loading: false, failedLoading: false, errorMessage: null, settingPresets: true, updateFailed: false}
    case customPresets.UPDATE_COMPLETE:
      return {...state, loading: false, failedLoading: false, errorMessage: null, settingPresets: false, updateFailed: false}
    case customPresets.UPDATE_COMPLETE:
      return {...state, loading: false, failedLoading: false, errorMessage: null, settingPresets: false, updateFailed: true}
    case cheatsheets.FETCH:
      return {...state, cheatsheetsLoading: true, failedLoading: false, errorMessage: null}
    case cheatsheets.RECEIVE:
      return {...state, cheatsheetsLoading: false, failedLoading: false, errorMessage: null, cheatsheets: action.payload}
    case cheatsheets.UPDATE:
      return {...state, cheatsheetsLoading: false, failedLoading: false, errorMessage: null, settingPresets: true, updateFailed: false}
    case cheatsheets.UPDATE_COMPLETE:
      return {...state, cheatsheetsLoading: false, failedLoading: false, errorMessage: null, settingPresets: false, updateFailed: false}
    case cheatsheets.UPDATE_COMPLETE:
      return {...state, cheatsheetsLoading: false, failedLoading: false, errorMessage: null, settingPresets: false, updateFailed: true}
    default:
      return state
  }
}

export const getAdmin = state => state.admin
