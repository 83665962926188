import isNull from "./isNull"

const getPPM = (pp, min) => {
  // if we have less than 1 projected minute return no ppm
  if (Number(min) < 1) return 0

  return Number(pp) / Number(min)
}

const getProjPtsForMins = (housePP, userPP, houseMin, userMin) => {
  // if user has never no projected min
  if (isNull(userMin) || userMin === '') {
    // if there are no user projected points
    if (isNull(userPP) || userPP === '') {
      return housePP
    } else {
      return userPP
    }
  } else {
    // we have user mins
    // if there are no user projected points
    if (isNull(userPP) || userPP === '') {
      return getPPM(housePP, houseMin) * Number(userMin)
    } else {
      // return just userPP
      return Number(userPP)
    }
  }
}

export default getProjPtsForMins