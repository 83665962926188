import { Row, Col, Menu, Select, Button, Spin } from 'antd'
import {
  CloudDownloadOutlined
} from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import * as lineupActions from '../../actions/lineup'
import * as analysisActions from '../../actions/analysis'
import * as accountActions from '../../actions/account'
import { week, season } from '../../utils/nfl-week'
import LastUpdated from '../../components/last-updated'

import { downloadCSV } from '../../utils/csv'

import PlayerPropsTable from './props-table'

const PlayerPropsContainer = styled.div`
  padding: 100px 2% 76px;
  min-height: 100vh;
  border-radius: 10px;
`

const downloadPlayerProps = (playerProps, propType) => {
  let csv = ''

  if (!playerProps[0].Name) {
    csv = 'Team,Odds\n'

    playerProps.forEach(p => {
      csv += `${p.Team},${p.oddsAmerican}\n`
    })
  } else {
    csv = 'Name,Team,Position,Odds\n'

    playerProps.forEach(p => {
      csv += `${p.Name},${p.Team},${p.Position},${p.oddsAmerican}\n`
    })
  }


  downloadCSV(csv, `props-nfl-week${week}-${season}-${propType}`)
}

const keys = ['MostRecYds', 'MostRushYds', 'MostPassYds', 'MostPoints']

const PlayerProps = ({ players, playerProps, subscription, fetchPlayers, fetchPlayerProps, fetchSubscription }) => {
  const [ propType, setPropType ] = useState("MostRecYds")
  const [ slate, setSlate ] = useState()

  useEffect(() => {
    fetchPlayerProps()
    fetchSubscription()
    fetchPlayers({ slate: 'Main', site: 'dk', sport: 'nfl', showdown: false })
  }, [])

  const mergePlayerTableWithProps = () => {
    let mergedProps = JSON.parse(JSON.stringify(playerProps.data))
    if (!players.data) return mergedProps

    keys.forEach(k => {
      let propArray = mergedProps[k] || []

      propArray.forEach((p, i) => {
        const playerProp = {...p}
        // find qb of team
        if (!p.Name) {
          let _matchingPlayers = players.data.filter(player => {
            return (player.TeamAbbrev === p.Team && player.Position === 'QB')
          })

          let projOwn = '-'
          if (_matchingPlayers.length) {
            _matchingPlayers.sort((a,b) => (Number(b.ProjOwn) - Number(a.ProjOwn)))
            projOwn = _matchingPlayers[0].ProjOwn
          }

          playerProp.projOwn = projOwn
          mergedProps[k][i] = playerProp
        } else {
          let _matchingPlayers = players.data.filter(player => {
            return (player.Name === p.Name && player.TeamAbbrev === p.Team && player.Position === p.Position)
          })

          let projOwn = '-'
          let salary = '-'
          if (_matchingPlayers.length) {
            _matchingPlayers.sort((a,b) => (Number(b.ProjOwn) - Number(a.ProjOwn)))
            projOwn = _matchingPlayers[0].ProjOwn
            salary = _matchingPlayers[0].Salary
          }

          playerProp.projOwn = projOwn
          playerProp.salary = salary
          mergedProps[k][i] = playerProp
        }
      })
    })

    return mergedProps
  }

  let downloadable = true

  const data = mergePlayerTableWithProps()

  return (
    <PlayerPropsContainer>
      <Menu selectedKeys={[propType]} mode="horizontal">
        <Menu.Item key="MostRecYds">
          <div onClick={() => {setPropType('MostRecYds')}}>Most Rec Yards</div>
        </Menu.Item>
        <Menu.Item key="MostRushYds">
          <div onClick={() => {setPropType('MostRushYds')}}>Most Rush Yards</div>
        </Menu.Item>
        <Menu.Item key="MostPassYds">
          <div onClick={() => {setPropType('MostPassYds')}}>Most Pass Yards</div>
        </Menu.Item>
        <Menu.Item key="MostPoints">
          <div onClick={() => {setPropType('MostPoints')}}>Most Points</div>
        </Menu.Item>
      </Menu>
      <div style={{margin: '20px 0px'}}>
        <Row>
          <Col lg={24}>
            <Button
              style={{float: 'right'}}
              onClick={() => downloadPlayerProps(playerProps.data[propType], propType)}
              disabled={players.loading || subscription.loading || !downloadable}
            >
              <CloudDownloadOutlined />Download Props
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={16} offset={4}>
          <div style={{marginTop: '30px'}}>
            <PlayerPropsTable
              data={data[propType] || []}
              loading={playerProps.loading || players.loading}
              propsKey={propType}
              permissions={subscription.permissions}
            />
          </div>
        </Col>
      </Row>
    </PlayerPropsContainer>
  )
}

export default connect(
  state => ({
    players: state.lineup.players,
    subscription: state.account.subscription,
    playerProps: state.analysis.playerProps
  }),
  {
    fetchPlayers: lineupActions.fetchPlayers,
    fetchPlayerProps: analysisActions.fetchPlayerProps,
    fetchSubscription: accountActions.fetchSubscription,
  }
)(PlayerProps)
