import React, { useState, Component } from 'react'
import { Row, Col, Card, Input, Button, Form } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as authActions from '../../actions/auth'
import * as accountActions from '../../actions/account'
import Logo from '../../assets/images/logo-filled.png'
import TOS from '../../assets/agreements/tos.pdf'
import Privacy from '../../assets/agreements/privacy.pdf'

const LoginContainer = styled.div`
  min-height: 94vh;
  padding: 70px;
  background: #eaecef;
`
const LoginCard = styled(Card)`
  border: 2px solid #cccccc !important;
  border-radius: 12px !important;
  margin: 100px 25% 0px 25% !important;
  box-shadow: 2px 2px 40px #e6e6e6;

  @media (max-width: 1280px) {
    margin: 100px 0px 0px 0px !important;
  }
`
const HeadingText = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
`
const StyledInput = styled(Input)`
  border-radius: 0px !important;
  font-size: 20px !important;
  line-height: 44px;

  .ant-input {
    border-radius: 0px !important;
    font-size: 20px !important;
    line-height: 44px;
  }

  .ant-input-prefix {
    margin-right: 7px;
  }

  .error {
    .ant-input {
      border: 1px solid red !important;
    }
  }
`
const LinkText = styled.div`
  font-size: 12px;
  color: blue;
`
const LogoImg = styled.img`
  height: 70px;
`
const Underline = styled.div`
  border-top: 1px solid #bfbfbf;

  &.error {
    border-top: 1px solid #ff6666;
  }
`
const ClickableText = styled.div`
  cursor: pointer;
  margin: 0px 2px;
`
const ErrorText = styled.div`
  text-align: center;
  color: #ff4d4d;
  font-size: 12px;
  margin: 10px 0px 10px 0px;
`
const StyledForm = styled(Form)`
  .ant-form-item-explain-error {
    text-align: center;
  }
  .ant-form-item-explain {
    text-align: center;
  }

  input {
    border: none !important;
  }
`

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

class LoginBox extends Component {

  componentWillUpdate(nextProps, nextState) {
    // Refresh if change in account
    if (
      this.props.auth.loggedin !== nextProps.auth.loggedin
    ) {
      const redirectTo = getQueryVariable('redirectTo')
      const plan = getQueryVariable('plan')
      const sport = getQueryVariable('sport')
      if (redirectTo == 'plans' && plan == 0) {
        window.location = window.location.protocol + "//" + window.location.host + "/" + (redirectTo || '')
      } else {
        window.location = window.location.protocol + "//" + window.location.host + "/" + (redirectTo || '') + (plan ? `?plan=${plan}` : '') + (sport ? `&sport=${sport}` : '')
      }
    }
  }

  render() {
    return (
      <Login
        initiateLogin={this.props.initiateLogin}
        error={this.props.auth.failedLogin ? (this.props.auth.errorMessage) : ''}
      />
    )
  }
}

const Login = ( { initiateLogin, error } ) => {
  const [ errors, setErrors ] = useState([])
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const setInputValue = (index, value) => {
    switch (index) {
      case 0:
        setEmail(value)
        if (!validateEmail(value)) {
          const _errors = [...errors]
          _errors[index] = "Invalid email address"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index]) {
            const _errors = [...errors]
            _errors[index] = undefined
            setErrors(_errors)
          }
        }
        break
      case 1:
        setPassword(value)
        if (!value) {
          const _errors = [...errors]
          _errors[index] = "Field cannot be empty"
          setErrors(_errors)
        } else {
          // field is ok
          if (errors[index]) {
            const _errors = [...errors]
            _errors[index] = undefined
            setErrors(_errors)
          }
        }
        break
      default:
        break
    }
  }

  const login = (values) => {
    // initate login
    initiateLogin({
      ...values
    })
  }

  const redirectTo = getQueryVariable('redirectTo')
  const plan = getQueryVariable('plan')
  const sport = getQueryVariable('sport')

  return (
    <LoginContainer>
      <LoginCard>
        <HeadingText>
          <LogoImg src={Logo} />
        </HeadingText>
        <HeadingText>
          Login
          <LinkText>
            <Link to={'/signup' + (redirectTo ? `?redirectTo=${redirectTo}` : '') + (plan ? `&plan=${plan}` : '') + (sport ? `&sport=${sport}` : '')}>Don't have an account? Signup</Link>
          </LinkText>
        </HeadingText>
        <ErrorText>{error}</ErrorText>
        <StyledForm onFinish={login} className="login-form">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter a your email address'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (validateEmail(getFieldValue('email'))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Invalid email'));
                },
              }),
            ]}
          >
            <Row style={{marginTop: '50px'}}>
              <Col lg={12} offset={6}>
                <StyledInput
                  bordered={false}
                  size="large"
                  placeholder={'Email'}
                  style={{width: '100%'}}
                  />
                <Underline />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter your password'
              }
            ]}
          >
            <Row style={{marginTop: '50px'}}>
              <Col lg={12} offset={6}>
                <StyledInput.Password
                  bordered={false}
                  size="large"
                  placeholder={'Password'}
                />
                <Underline />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <div style={{marginTop: '40px'}}>
              <div style={{textAlign: 'center', marginTop: '40px'}}>
                <Link style={{color: '#999999'}} to='/forgot-password'>Forgot password?</Link>
              </div>
              <div style={{marginTop: '40px', textAlign: 'center'}}>
                <Button
                  size={'large'}
                  shape='round'
                  type='primary'
                  style={{width: '160px'}}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form.Item>
        </StyledForm>
      </LoginCard>
    </LoginContainer>
  )
}

export default connect(
  state => ({
    auth: state.auth,
    account: state.account
  }),
  {
    initiateLogin: authActions.initiateLogin,
    initiateSignup: authActions.initiateSignup,
    logout: authActions.logout,
    fetchForgotPassword: accountActions.fetchForgotPassword
  }
)(LoginBox)
