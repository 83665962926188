// Times
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(customParseFormat)

const getLockedTeams = ({ players }) => {
  const lockedTeams = []
  const TeamSeen = {}

  const now = dayjs().tz("America/New_York")
  // const now = dayjs.tz("2023-10-24T20:00:00Z", "YYYY-MM-DDTHH:mm:ssZ", "America/New_York")

  players.forEach(p => {
    const team = p.TeamAbbrev
    if (!TeamSeen[team]) {
      const gameStart = dayjs.tz(p.GameStart, "YYYY-MM-DDTHH:mm:ssZ", "America/New_York")

      // find diff in minutes
      if (now.isAfter(gameStart)) {
        lockedTeams.push(team)
      }

      TeamSeen[team] = true
    }
  })

  return lockedTeams
}

export default getLockedTeams