export const getTeamsInSlate = (players) => {
  const teamsLookup = {}
  let teamCount = 0
  players.forEach(p => {
    if (!teamsLookup[p.TeamAbbrev]) {
      teamCount = teamCount + 1
      teamsLookup[p.TeamAbbrev] = true
    }
  })

  return teamCount
}
