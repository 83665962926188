import { Button, Select, Space, Popconfirm, Spin } from "antd"
import React, {useState} from 'react'
import styled from "styled-components"

const Heading = styled.div`
  text-align: left;
  padding: 5px;
  font-weight: 600;
`

const DEFAULT = 'Default'

const CustomPresets = ({setPreset, disabled, nameOptions=[], loading=false, title='', erasing=''}) => {
  const [ selectedIndex, setSelectedIndex ] = useState(0)

  let items = [
    {
      label: loading ? (<Spin size='small' />) : DEFAULT,
      value: 0
    }
  ]

  nameOptions.forEach((name, i) => {
    items.push({
      label: name,
      value: i + 1
    })
  })

  return (
    <div style={{marginTop: '18px'}}>
      <Heading>{title || 'Presets'}</Heading>
      <Select
        options={items}
        value={selectedIndex}
        onChange={value => {setSelectedIndex(value)}}
        style={{minWidth: '140px'}}
        disabled={loading}
      >
      </Select>
      {
        selectedIndex === 0 ? (
          <Button
            onClick={() => {setPreset(selectedIndex)}}
            disabled={disabled || loading }
          >
            Use Presets
          </Button>
        ) : (
          <Popconfirm
            title='Use Custom Presets'
            description={`This might erase existing ${erasing || "Groups and Settings"}. Do you wish to continue?`}
            onConfirm={() => {setPreset(selectedIndex)}}
            okText="Yes"
            cancelText="No"
          >
            <Button
              disabled={disabled || loading}
            >
              Use Presets
            </Button>
          </Popconfirm>
        )
      }
    </div>
  )
}

export default CustomPresets