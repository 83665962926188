export const nflTeamNames = {
  "49ers":"SF",
  "Bears":"CHI",
  "Bengals":"CIN",
  "Bills":"BUF",
  "Broncos":"DEN",
  "Browns":"CLE",
  "Buccaneers":"TB",
  "Cardinals":"ARI",
  "Chargers":"LAC",
  "Chiefs":"KC",
  "Colts":"IND",
  "Cowboys":"DAL",
  "Dolphins":"MIA",
  "Eagles":"PHI",
  "Falcons":"ATL",
  "Giants":"NYG",
  "Jaguars":"JAC",
  "Jets":"NYJ",
  "Lions":"DET",
  "Packers":"GB",
  "Panthers":"CAR",
  "Patriots":"NE",
  "Raiders":"OAK",
  "Rams":"LAR",
  "Ravens":"BAL",
  "Washington":"WAS",
  "Saints":"NO",
  "Seahawks":"SEA",
  "Steelers":"PIT",
  "Texans":"HOU",
  "Titans":"TEN",
  "Vikings":"MIN"
}

export const nbaTeamNames = {
  "76ers": "PHI",
  "Heat": "MIA",
  "Magic": "ORL",
  "Bulls": "CHI",
  "Knicks": "NYK",
  "Raptors": "TOR",
  "Pacers": "IND",
  "Hawks": "ATL",
  "Celtics": "BOS",
  "Nets": "BKN",
  "Wizards": "WAS",
  "Cavaliers": "CLE",
  "Pistons": "DET",
  "Bucks": "MIL",
  "Hornets": "CHA",
  "Kings": "SAC",
  "Thunder": "OKC",
  "Pelicans": "NO",
  "Timberwolves": "MIN",
  "Rockets": "HOU",
  "Mavericks": "DAL",
  "Nuggets": "DEN",
  "Grizzlies": "MEM",
  "Warriors": "GS",
  "Clippers": "LAC",
  "Suns": "PHX",
  "Jazz": "UT",
  "Spurs": "SA",
  "Blazers": "POR",
  "Lakers": "LAL"
}

export const mlbTeamNames = {
  "White Sox": "CWS",
  "Guardians": "CLG",
  "Tigers": "DET",
  "Royals": "KCR",
  "Twins": "MIN",
  "Cubs": "CHI",
  "Reds": "CIN",
  "Brewers": "MIL",
  "Pirates": "PIT",
  "Cardinals": "SLC",
  "Orioles": "BAL",
  "Red Sox": "BOS",
  "Yankees": "NYY",
  "Rays": "TB",
  "Blue Jays": "TOR",
  "Braves": "ATL",
  "Marlins": "MIA",
  "Mets": "NYM",
  "Phillies": "PHI",
  "Nationals": "DC",
  "Astros": "HOU",
  "Angels": "LAA",
  "Athletics": "OAK",
  "Mariners": "SEA",
  "Rangers": "TX",
  "Diamondbacks": "AZ",
  "Rockies": "CO",
  "Dodgers": "LAD",
  "Padres": "SD",
  "Giants": "SF"
}