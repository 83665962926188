import { JWT_LOOKUP } from "../constants/api"
import { framed, authed } from "../constants/partner"

export const getToken = () => {
  let token = localStorage.getItem(JWT_LOOKUP)

  // if (framed && !authed) {
  //   token = process.env.REACT_APP_TEMP_TOKEN
  // }

  return token
}