import { Menu, Dropdown, Row, Col } from 'antd'
import React from 'react'
import styled from 'styled-components'
import Logo from '../../assets/images/logo-text.png'
import LogoSmall from '../../assets/images/logo-white.png'

import { week as defaultWeek, season as defaultSeason } from '../../utils/nfl-week'

import { MainSlate } from '../../constants/slates'

import Login from './login'

const NavBarContainer = styled.div`
  background: #1d3557;
  min-height: 90px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  text-align: center;

  a {
    text-decoration: none;
    color: #f1faee;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    text-align: center;
    padding-top: 3px;
  }

  a:hover {
    color: white;
  }

  @media (max-width: 700px) {
    float: left;

    a {
      font-size: 14px;
    }
  }
`
const StyledLogoImg = styled.img`
  height: 30px;
`
const LoginDiv = styled.div`
  float: right;
  margin-top: -3px;

  @media (max-width: 700px) {
    float: right;

    a {
      font-size: 14px;
    }
  }
`

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

// const items = [
//   getItem('NFL', 'nfl', null, [
//     getItem('DraftKings', 'dk'),
//     getItem('FanDuel', 'fd'),
//     getItem('Yahoo', 'ya'),
//   ]),
//   getItem('NBA', 'nba', null, [
//     getItem((
//       <a href='/optimizer/classic/nba/dk/Main'>
//         DraftKings
//       </a>
//     ), 'dk'),
//     getItem('FanDuel', 'fd')
//   ]),
// ]; // submenu keys of first level

// const dropdownOptimizer = (
//   <Menu
//     key='nfl' 
//     mode='inline'
//     title={(
//       <span>NFL</span>
//     )}
//     items={items}
//   />
// )
const dropdownLineupBuilder = (
  <Menu>
      <Menu.ItemGroup title="NFL">
        <Menu.Item>
          <a href={`/optimizer/classic/nfl/dk/${MainSlate}`}>
            DraftKings
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href={`/optimizer/classic/nfl/fd/${MainSlate}`}>
            FanDuel
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href={`/optimizer/classic/nfl/ya/${MainSlate}`}>
            Yahoo
          </a>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title={'NBA'}>
        <Menu.Item>
          <a href={`/optimizer/classic/nba/dk/Main`}>
            DraftKings
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href={`/optimizer/classic/nba/fd/Main`}>
            FanDuel
          </a>
        </Menu.Item>
      </ Menu.ItemGroup>
  </Menu>
)
const dropdownNBA = (
  <Menu>
    
  </Menu>
)

const dropdownAnalytics = (
  <Menu>
    <Menu.ItemGroup title={'NFL'} >
      <Menu.Item>
        <a href={`/analysis/nfl/dk/${MainSlate}/stacks`}>
          DraftKings Stacks
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={`/analysis/nfl/fd/${MainSlate}/stacks`}>
          FanDuel Stacks
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={`/analysis/nfl/ya/${MainSlate}/stacks`}>
          Yahoo Stacks
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={`/props`}>
          Player Props
        </a>
      </Menu.Item>
    </Menu.ItemGroup>
    <Menu.ItemGroup title={'NBA'} >
      <Menu.Item>
        <a href={`/analysis/nba/dk/Main/on-off`}>
          On/Off Value Report
        </a>
      </Menu.Item>
    </Menu.ItemGroup>
    
  </Menu>
)
const dropdownProjections = (
  <Menu>
    <Menu.ItemGroup title={'NFL'} >
      <Menu.Item>
        <a href={`/projections/nfl/dk`}>
          DraftKings
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={`/projections/nfl/fd`}>
          FanDuel
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={`/projections/nfl/ya`}>
          Yahoo
        </a>
      </Menu.Item>
    </Menu.ItemGroup>
  </Menu>
)

const NavBar = () => (
  <NavBarContainer>
    <Row style={{padding: '30px 5% 0px 5%'}}>
      <Col lg={5} md={3} sm={3} xs={2} style={{textAlign: 'left'}}>
          <a href="/">
            <picture>
              <source media="(min-width: 1111px" srcSet={Logo} />
              <source media="(min-width: 300px" srcSet={LogoSmall} />
              <StyledLogoImg src={LogoSmall} alt="" />
            </picture>
          </a>
      </Col>
      <Col lg={3} md={5} sm={5} xs={5} style={{marginLeft: '10px'}}>
          <Dropdown overlay={dropdownLineupBuilder} trigger={['click']}>
            <a className="ant-dropdown-link" >
              Lineup Builder
            </a>
          </Dropdown>
      </Col>
      <Col lg={3} md={4} sm={0} xs={0}>
          <Dropdown overlay={dropdownProjections} trigger={['click']}>
            <a className="ant-dropdown-link" >
              Projections
            </a>
          </Dropdown>
      </Col>
      <Col lg={3} md={4} sm={5} xs={5}>
      <Dropdown overlay={dropdownAnalytics} trigger={['click']}>
            <a className="ant-dropdown-link" >
              Analytics
            </a>
          </Dropdown>
      </Col>
      <Col lg={9} md={7} sm={10} xs={10}>
        <LoginDiv>
          <Login />
        </LoginDiv>
      </Col>
    </Row>
  </NavBarContainer>
)

export default NavBar
