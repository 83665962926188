import React from 'react'
import ReactDOM from 'react-dom'

import configureStore from './bootstrap/configure-store'
import App from './bootstrap/app'
import FramedApp from './bootstrap/opt-frame-app'

import { framed } from './constants/partner'

const { store } = configureStore()
export default store

// Random number is used so hot reloading works with `react-loadable`
const render = Component => {
  ReactDOM.render(
    <Component
      key={process.env.NODE_ENV === 'development' ? Math.random() : undefined}
      store={store}
    />,
    document.getElementById('root')
  )
}

render(framed ? FramedApp : App)

if (module.hot) {
  module.hot.accept('./bootstrap/app', () => {
    render(framed ? FramedApp : App)
  })
}

// registerServiceWorker()
