import React from 'react'
import { Dropdown, Space } from 'antd'
import { weekOptions, seasonOptions } from '../utils/nfl-week'
import { CaretDownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import DKLogo from '../assets/images/logos/dk.png'
import FDLogo from '../assets/images/logos/fd.png'
import YALogo from '../assets/images/logos/ya.png'

const DrowdownContainer = styled.div`
  display: inline-block;
`
const SmallCarrot = styled.span`
  font-size: 10px;
  margin-left: -5px;
`

const siteToLogoMap = {
  'dk': (
    <img src={DKLogo} style={{height: '28px'}} />
  ),
  'fd': (
    <img src={FDLogo} style={{height: '28px'}} />
  ),
  'ya': (
    <img src={YALogo} style={{height: '28px'}} />
  )
}

const NFLWeekPicker = ({ selectedWeek, selectedSeason, changeDateInfo, site, updateSite }) => {
  const _seasonOptions = seasonOptions.map(season => {
    return {
      label: <div onClick={() => {changeDateInfo({counter: selectedWeek, season})}}> {season} </div>,
      key: season
    }
  })
  const _weekOptions = weekOptions.map(week => {
    return {
      label: <div onClick={() => {changeDateInfo({counter: week, season: selectedSeason})}}> {week} </div>,
      key: week
    }
  })
  const _siteOptions = Object.keys(siteToLogoMap).map(site => {
    return {
      label: <div onClick={() => {updateSite(site)}}> {siteToLogoMap[site]} </div>,
      key: site
    }
  })

  return (
    <DrowdownContainer>
      <Dropdown
        menu={{items: _siteOptions}}
        trigger={['click']}
      >
        <Space>
          {siteToLogoMap[site]}<SmallCarrot style={{marginRight: '10px'}}><CaretDownOutlined /></SmallCarrot>
        </Space>
      </Dropdown>
      <Dropdown
        menu={{ items: _seasonOptions}}
        trigger={['click']}
      >
        <Space>
          {selectedSeason}<SmallCarrot><CaretDownOutlined /></SmallCarrot>
        </Space>
      </Dropdown>
      <span style={{ margin: '10px', fontSize: '14px'}} >Week</span>
      <Dropdown
        menu={{items: _weekOptions}}
        trigger={['click']}
        overlayStyle={{
          'max-height': '250px',
          'overflow': 'auto',
          'min-width': '100px'
        }}
      >
        <Space>
          {selectedWeek}<SmallCarrot><CaretDownOutlined /></SmallCarrot>
        </Space>
      </Dropdown>
    </DrowdownContainer>
  )
}

export default NFLWeekPicker
