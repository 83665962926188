export default (rawLus, numberOfLineups) => {
  const playersOwnedCounts = {}

  rawLus.forEach(p => {
    let rosterPosition = String(p.Id).split("|")[1] || ''
    const playerKey = getPlayerKey(p, rosterPosition)

    if (!playersOwnedCounts[playerKey]) {
      const _p = { ...p }
      if (rosterPosition)
        _p.RosterPosition = rosterPosition

      playersOwnedCounts[playerKey] = {
        player: _p,
        count: 0
      }
    }

    playersOwnedCounts[playerKey].count = playersOwnedCounts[playerKey].count + 1
  })

  let playersWithExp = []
  Object.keys(playersOwnedCounts).forEach((pk, i) => {
    const _p = playersOwnedCounts[pk].player
    _p.Exp = (playersOwnedCounts[pk].count * 100.0) / numberOfLineups

    playersWithExp.push(_p)
  })

  return playersWithExp
}

export const mergeLineupsWithPlayerTable = (playersWithExp, players) => {
  const _players = []

  playersWithExp.forEach(p => {
    const k = getPlayerKey(p, p.RosterPosition)

    players.forEach(_p => {
      if (getPlayerKey(_p, _p.RosterPosition) === k) {
        _players.push({
          ..._p,
          Exp: p.Exp
        })
        return
      }
    })
  })

  return _players
}

const getPlayerKey = (p, rosterPosition='') => (
  `${p.Name}-${p.Position}-${rosterPosition}`
)
