import { EXP_SETTINGS_KEY } from '../constants/local-storage-keys'
import { teamNameCorrections } from '../constants/team-name-corrections'
import getProjPtsForMins from './get-projPts-for-mins'
import isNull from './isNull'

const mergePlayerTableDbProjections = (playerTable, dbProj, noPosition=false) => {
    let DEFAULT_MIN_EXP = 0
    let DEFAULT_MAX_EXP = 100
    const exposureDefaults = JSON.parse(localStorage.getItem(EXP_SETTINGS_KEY))

    if (exposureDefaults && exposureDefaults.zeroed)
      DEFAULT_MAX_EXP = 0

    const projectionsMap = {}
    dbProj.forEach((projRow, i) => {
        // FIXME: use IDs for player table
        projectionsMap[projRow.Id] = {
          MinExp: projRow.MinExp,
          MaxExp: projRow.MaxExp,
          UserProj: projRow.UserProj,
          UserOwn: projRow.UserOwn,
          UserMins: projRow.UserMins,
          Boost: projRow.Boost,
          ProjPtsAdjusted: projRow.ProjPtsAdjusted
        }
    })

    return playerTable.map(player => {
      if (projectionsMap[player.Id]) {

        return {
          ...player,
          MinExp: (!isNull(projectionsMap[player.Id].MinExp)) ? projectionsMap[player.Id].MinExp : DEFAULT_MIN_EXP,
          MaxExp: (!isNull(projectionsMap[player.Id].MaxExp)) ? projectionsMap[player.Id].MaxExp : DEFAULT_MAX_EXP,
          UserProj: (!isNull(projectionsMap[player.Id].UserProj)) ? projectionsMap[player.Id].UserProj : player.UserProj,
          UserOwn: (!isNull(projectionsMap[player.Id].UserOwn)) ? projectionsMap[player.Id].UserOwn : player.UserOwn,
          HouseProjPts: player.HouseProjPts ? player.HouseProjPts : player.ProjPts,
          HouseOwn: player.HouseOwn ? player.HouseOwn : player.ProjOwn,
          ProjPts: (!isNull(projectionsMap[player.Id].UserProj)) ? projectionsMap[player.Id].UserProj : (projectionsMap[player.Id].ProjPtsAdjusted || player.ProjPts),
          ProjOwn: (!isNull(projectionsMap[player.Id].UserOwn)) ? projectionsMap[player.Id].UserOwn : player.ProjOwn,
          UserMins: (!isNull(projectionsMap[player.Id].UserMins)) ? projectionsMap[player.Id].UserMins : player.UserMins,
          HouseProjMins: player.HouseProjMins ? player.HouseProjMins : player.ProjMins,
          Min: (!isNull(projectionsMap[player.Id].UserMins)) ? projectionsMap[player.Id].UserMins : player.Min,
          Boost: (!isNull(projectionsMap[player.Id].Boost)) ? projectionsMap[player.Id].Boost : player.Boost,
          TeamAbbrev: teamNameCorrections[player.TeamAbbrev] || player.TeamAbbrev,
          Opp: teamNameCorrections[player.Opp] || player.Opp,
          ProjPtsAdjusted: projectionsMap[player.Id].ProjPtsAdjusted
        }
      } else {
        return {
          ...player,
          MinExp: DEFAULT_MIN_EXP,
          MaxExp: DEFAULT_MAX_EXP,
          TeamAbbrev: teamNameCorrections[player.TeamAbbrev] || player.TeamAbbrev,
          Opp: teamNameCorrections[player.Opp] || player.Opp
        }
      }
    })
}

const projMapKey = (playerName, playerPosition, playerTeamName, rosterPosition, noPosition) => {
  if (noPosition) {
    return `${playerName}-${playerTeamName}-${rosterPosition}`
  } else {
    return `${playerName}-${playerPosition}-${playerTeamName}-${rosterPosition}`
  }
}

export default mergePlayerTableDbProjections
