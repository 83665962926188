import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import Initializer from './initializer'
import NavBar from '../components/nav-bar'
import Home from '../containers/home'
import LineupGenerator from '../containers/lineup-generator'
import Maintenence from '../containers/home/maintenance'
import Footer from '../containers/home/footer'
import MyAccount from '../containers/my-account'
import Plans from '../containers/plans'
import ResetPassword from '../containers/reset-password'
import Analysis from '../containers/analysis'
import SignUp from '../containers/home/signup'
import Login from '../containers/home/login'
import ForgotPassword from '../containers/home/forgot-password'
import Projections from '../containers/projections'
import Props from '../containers/props'
import LearnMore from '../containers/home/learn-more'

import { MainSlate } from '../constants/slates'

import './app.css'

const maintenance = false

const App = ({ store, testElement }) => (
  <Provider store={store}>
    <Initializer>
    <BrowserRouter>
      <div id="router-root">
        <Helmet>
          <title>DFSForecast</title>
        </Helmet>
          <Route exact path="*" component={NavBar} />
          <div id="scroll-root">
              {
                maintenance ? (
                  <Switch>
                    <Route exact path="*" component={Maintenence} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/learn-more" component={LearnMore} />
                    <Route exact path="/account" component={MyAccount} />
                    <Route exact path="/plans" component={Plans} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/optimizer/:sport/:site/:slate" component={LineupGenerator} />
                    <Route exact path="/optimizer/:contestType/:sport/:site/:slate" component={LineupGenerator} />
                    <Route exact path="/optimizer" render={() => (
                        <Redirect to="/" />
                      )} />
                    <Route exact path="/optimizer/nfl/dk" render={() => (
                        <Redirect to={`/optimizer/nfl/dk/${MainSlate}`} />
                      )} />
                    <Route exact path="/optimizer/nfl/fd" render={() => (
                        <Redirect to={`/optimizer/nfl/fd/${MainSlate}`} />
                      )} />
                    <Route exact path="/analysis/:sport/:site/:slate/:type" component={Analysis} />
                    <Route exact path="/projections/:sport/:site" component={Projections} />
                    <Route exact path="/projections/:contestType/:sport/:site" component={Projections} />
                    <Route exact path="/props" component={Props} />
                  </Switch>
                )
              }
          </div>
        <Footer />
      </div>
    </BrowserRouter>
  </Initializer>
  </Provider>
)

App.propTypes = {
  // State
  store: PropTypes.shape({}).isRequired,

  // Testing
  testElement: PropTypes.element
}

App.defaultProps = {
  // Testing
  testElement: null
}

export default App
